<template>
    <main class="public-container">
        <noscript>This page requires JavaScript to be enabled in your browser.</noscript>
        <div class="content-wrapper">
            <div class="content" v-if="!formSubmitted">
                <form class="public-form" @submit.prevent="submitFormHandler">
                    <ApplicationSection title=" TX 50-114" subtitle="Residence Homestead Exemption Application">
                        <p class="text-attention">This form will not be received by the Appraisal District until you
                            press Submit Application and receive a submission confirmation number.</p>
                        <div class="element-container">
                            <label class="form-label" for="appraisal-district-account-number">Appraisal District Account
                                Number (if known):</label>
                            <input type="text" id="appraisal-district-account-number" maxlength="255"
                                v-model="formFields.appraisal_district_account_number"
                                :disabled="isADAccountNumDisabled">
                        </div>

                        <div class="element-container">
                            <span class="form-label form-label-required">
                                Are you filing a late application? </span>
                            <fieldset>
                                <input v-model="formFields.filing_late_application" type="radio" class="custom-radio"
                                    name="filing-late-application" id="filing-late-application-1" :value="true" required
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="filing-late-application-1">Yes</label>
                                <input v-model="formFields.filing_late_application" type="radio" class="custom-radio"
                                    name="filing-late-application" id="filing-late-application-0" :value="false"
                                    required>
                                <label for="filing-late-application-0">No</label>
                            </fieldset>
                        </div>

                        <div class="element-container">
                            <span class="form-label form-label-required">
                                Select the first year you believe you qualify for an exemption
                            </span>
                            <select id="start-year-of-exemption" v-model="formFields.start_year_of_exemption" required
                                @invalid.capture.prevent="elementRequiredHandler">
                                <option></option>
                                <option v-for="year in taxYearsAvailable" :key="year">{{ year }}</option>
                            </select>
                        </div>

                        <p class="element-description">
                            GENERAL INFORMATION: Property owners applying for a residence homestead exemption file this
                            form and supporting documentation with the appraisal district in each county in which the
                            property is located (Tax Code Sections 11.13, 11.131, 11.132, 11.133, 11.134 and 11.432).
                        </p>
                    </ApplicationSection>
                    <!-- end introduction section -->

                    <ApplicationSection title="SECTION 1: Exemption(s) Requested (Select all that apply)">
                        <!-- BEGIN EXEMPTION LIST -->
                        <div class="public-form-exemption-list field-group">
                            <!-- exemption General Homestead -->
                            <div class="element-container" id="general-exemption-container">
                                <input type="checkbox" class="custom-checkbox" id="exemption-general"
                                    v-model="formFields.exemption_general">
                                <label for="exemption-general">General Residence Homestead Exemption</label>
                            </div>

                            <!-- exemption Disabled Person-->
                            <div class="element-container">
                                <input type="checkbox" class="custom-checkbox" id="exemption-disabled-person"
                                    v-model="formFields.exemption_disabled_person">
                                <label for="exemption-disabled-person">Disabled Person</label>

                                <div class="public-form-additional-info-conditional">
                                    <FileUploader id="exemption-disabled-documentation-file"
                                        :required="formFields.exemption_disabled_person" :disabled="!isFormLoaded"
                                        :accept="ACCEPT_FILE_TYPES" :elementRequiredHandler="elementRequiredHandler"
                                        @change="uploadFiles">
                                        <template #label>
                                            <AdditionalDocumentationRequired>
                                                <p>Examples include: current SSA Disability documentation or other
                                                    qualifying
                                                    documentation. (Contact the appraisal district for information on
                                                    what other
                                                    documentation is acceptable.)</p>
                                                <p>For additional information, visit the comptroller's website here:
                                                    <a href="https://comptroller.texas.gov/taxes/property-tax/exemptions/age65older-disabled-faq.php"
                                                        rel="noopener" class="public-link" target="_blank">
                                                        Age 65 or Older or Disabled Exemptions Frequently Asked
                                                        Questions
                                                    </a>
                                                </p>
                                            </AdditionalDocumentationRequired>
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus containerID="exemption-disabled-documentation-file" />
                                </div>
                            </div>

                            <!-- exemption Age 65+ -->
                            <div class="element-container">
                                <input type="checkbox" class="custom-checkbox" id="exemption-age-65-plus"
                                    v-model="formFields.exemption_age_65_plus">
                                <label for="exemption-age-65-plus">Person Age 65 or Older (or Surviving Spouse)</label>
                            </div>

                            <!-- exemption Disabled Veteran -->
                            <div class="element-container">
                                <input type="checkbox" class="custom-checkbox" id="exemption-disabled-veteran"
                                    v-model="formFields.exemption_disabled_veteran">
                                <label for="exemption-disabled-veteran">100 Percent Disabled Veteran (or Surviving
                                    Spouse)</label>

                                <div class="public-form-additional-info-conditional">
                                    <FileUploader id="exemption-disabled-veteran-documentation-file"
                                        :required="formFields.exemption_disabled_veteran" :disabled="!isFormLoaded"
                                        :accept="ACCEPT_FILE_TYPES" :elementRequiredHandler="elementRequiredHandler"
                                        @change="uploadFiles">
                                        <template #label>
                                            <AdditionalDocumentationRequired>
                                                <p>Example: current VA Military Member or Surviving Spouse benefit
                                                    letter
                                                    indicating the member is/was rated at 100% disability or
                                                    unemployable.
                                                </p>
                                            </AdditionalDocumentationRequired>
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus
                                        containerID="exemption-disabled-veteran-documentation-file" />
                                </div>

                                <div class="public-form-additional-info-conditional">
                                    <span class="form-label form-label-required">
                                        Is the disability a permanent total disability as determined by the U.S.
                                        Department
                                        of Veterans Affairs under 38 C.F.R. Section 4.15?
                                    </span>
                                    <fieldset>
                                        <input type="radio" class="custom-radio" name="is-veteran-disability-permanent"
                                            id="is-veteran-disability-permanent-1"
                                            v-model="formFields.is_veteran_disability_permanent" :value="true"
                                            :required="formFields.exemption_disabled_veteran"
                                            @invalid.capture.prevent="elementRequiredHandler">
                                        <label for="is-veteran-disability-permanent-1">Yes</label>

                                        <input type="radio" class="custom-radio" name="is-veteran-disability-permanent"
                                            id="is-veteran-disability-permanent-0"
                                            v-model="formFields.is_veteran_disability_permanent" :value="false"
                                            :required="formFields.exemption_disabled_veteran">
                                        <label for="is-veteran-disability-permanent-0">No</label>
                                    </fieldset>
                                </div>
                            </div>

                            <!-- exemption Surviving Spouse Armed Services -->
                            <div class="element-container">
                                <input type="checkbox" class="custom-checkbox"
                                    id="exemption-surviving-spouse-armed-services"
                                    v-model="formFields.exemption_surviving_spouse_armed_services">
                                <label for="exemption-surviving-spouse-armed-services">Surviving Spouse of an Armed
                                    Services
                                    Member Killed or Fatally Injured in the Line of Duty</label>

                                <div class="public-form-additional-info-conditional">
                                    <FileUploader id="exemption-surviving-spouse-armed-services-documentation-file"
                                        :required="formFields.exemption_surviving_spouse_armed_services"
                                        :disabled="!isFormLoaded" :accept="ACCEPT_FILE_TYPES"
                                        :elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
                                        <template #label>
                                            <AdditionalDocumentationRequired>
                                                <p>Examples include: a DD1300 or a letter from the Secretary of Defense
                                                    indicating
                                                    the military member died while on active duty.</p>
                                            </AdditionalDocumentationRequired>
                                        </template>
                                    </FileUploader>

                                    <ApplicationFileUploadStatus
                                        containerID="exemption-surviving-spouse-armed-services-documentation-file" />
                                </div>
                            </div>

                            <!-- exemption Surviving Spouse First Responder -->
                            <div class="element-container">
                                <input type="checkbox" class="custom-checkbox"
                                    id="exemption-surviving-spouse-first-responder"
                                    v-model="formFields.exemption_surviving_spouse_first_responder">
                                <label for="exemption-surviving-spouse-first-responder">
                                    Surviving Spouse of a First Responder Killed in the Line of Duty
                                </label>

                                <div class="public-form-additional-info-conditional">
                                    <FileUploader id="exemption-surviving-spouse-first-responder-documentation-file"
                                        :required="formFields.exemption_surviving_spouse_first_responder"
                                        :disabled="!isFormLoaded" :accept="ACCEPT_FILE_TYPES"
                                        :elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
                                        <template #label>
                                            <AdditionalDocumentationRequired>
                                                <p>Example: a letter from the first responder's retirement system
                                                    indicating the
                                                    first responder
                                                    died while serving in the line of duty.</p>
                                            </AdditionalDocumentationRequired>
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus
                                        containerID="exemption-surviving-spouse-first-responder-documentation-file" />
                                </div>
                            </div>

                            <!-- exemption Donated Residence -->
                            <div class="element-container">
                                <input type="checkbox" class="custom-checkbox" id="exemption-donated-residence"
                                    v-model="formFields.exemption_donated_residence">
                                <label for="exemption-donated-residence">
                                    Donated Residence of Partially Disabled Veteran (or Surviving Spouse)
                                </label>

                                <div class="public-form-additional-info-conditional"
                                    v-if="formFields.exemption_donated_residence">
                                    <FileUploader id="exemption-donated-residence-documentation-file"
                                        :required="formFields.exemption_donated_residence" :disabled="!isFormLoaded"
                                        :accept="ACCEPT_FILE_TYPES" :elementRequiredHandler="elementRequiredHandler"
                                        @change="uploadFiles">
                                        <template #label>
                                            <AdditionalDocumentationRequired>
                                                <p>Example: current VA Surviving Spouse benefit letter.</p>
                                            </AdditionalDocumentationRequired>
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus
                                        containerID="exemption-donated-residence-documentation-file" />
                                </div>

                                <div class="public-form-additional-info-conditional"
                                    v-if="formFields.exemption_donated_residence">
                                    <label class="form-label form-label-required"
                                        for="percent-disability-rating">Percent
                                        Disability Rating</label>
                                    <input id="percent-disability-rating" v-model="formFields.percent_disability_rating"
                                        type="number" min="0" max="100" step="10"
                                        :required="formFields.exemption_donated_residence"> %
                                </div>
                            </div>
                        </div>
                        <!-- END EXEMPTION LIST -->

                        <div class="element-container">
                            <span class="form-label form-label-required" id="living-on-property">
                                Do you live in the property for which you are seeking this residence homestead
                                exemption?
                            </span>
                            <fieldset>
                                <input type="radio" class="custom-radio" name="living-on-property"
                                    id="living-on-property-1" v-model="formFields.living_on_property" :value="true"
                                    required @invalid.capture.prevent="elementRequiredHandler">
                                <label for="living-on-property-1">Yes</label>
                                <input type="radio" class="custom-radio" name="living-on-property"
                                    id="living-on-property-0" v-model="formFields.living_on_property" :value="false"
                                    required>
                                <label for="living-on-property-0">No</label>
                            </fieldset>
                        </div>

                        <div id="deceased-spouse-container" class="element-container" v-if="showDeceasedSpouseFields">
                            <div class="element-container">
                                <label class="form-label" for="name-deceased-spouse">Name of Deceased Spouse</label>
                                <input type="text" id="name-deceased-spouse" maxlength="255"
                                    v-model="formFields.deceased_spouse_name" autocomplete="name" spellcheck="false">
                            </div>
                            <div class="element-container">
                                <label class="form-label" for="deceased-spouse-date-of-death">Date of Spouse's
                                    Death
                                </label>
                                <DateComboBox v-model="formFields.deceased_spouse_deathdate"
                                    :id="'deceased-spouse-date-of-death'" />
                            </div>
                        </div>

                        <div class="element-container">
                            <span class="form-label form-label-required">
                                Cooperative Housing: Do you have an exclusive right to occupy this property because
                                you own stock in a cooperative housing corporation?
                            </span>
                            <fieldset>
                                <input type="radio" class="custom-radio" name="co-op-housing" id="co-op-housing-1"
                                    :value="true" data-on="1" required v-model="formFields.co_op_housing"
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="co-op-housing-1">Yes</label>

                                <input type="radio" class="custom-radio" name="co-op-housing" id="co-op-housing-0"
                                    :value="false" required v-model="formFields.co_op_housing">
                                <label for="co-op-housing-0">No</label>

                                <div class="public-form-additional-info-conditional">
                                    <label for="co-op-housing-name" class="form-label form-label-required">
                                        Name of Cooperative Housing Corporation
                                    </label>
                                    <input type="text" id="co-op-housing-name" maxlength="255"
                                        v-model="formFields.co_op_housing_name"
                                        :required="getRequiredAttrValue(formFields.co_op_housing)">
                                </div>
                            </fieldset>
                        </div>

                        <div class="element-container">
                            <span class="form-label form-label-required">
                                Were you receiving a homestead exemption on your previous residence?
                            </span>
                            <fieldset>
                                <input type="radio" class="custom-radio" name="receiving-exemption-previous-residence"
                                    id="receiving-exemption-previous-residence-1"
                                    v-model="formFields.receiving_exemption_previous_residence" :value="true" required
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="receiving-exemption-previous-residence-1">Yes</label>
                                <input type="radio" class="custom-radio" name="receiving-exemption-previous-residence"
                                    id="receiving-exemption-previous-residence-0"
                                    v-model="formFields.receiving_exemption_previous_residence" :value="false" required>
                                <label for="receiving-exemption-previous-residence-0">No</label>
                            </fieldset>
                        </div>

                        <div class="element-container" v-if="anyExemptionSelectedBesidesGeneral">
                            <span class="form-label form-label-required">
                                Are you transferring an exemption from a previous residence?
                            </span>

                            <fieldset>
                                <input type="radio" class="custom-radio"
                                    name="transferring-exemption-previous-residence"
                                    id="transferring-exemption-previous-residence-1" :value="true"
                                    v-model="formFields.transferring_exemption_previous_residence" required
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="transferring-exemption-previous-residence-1">Yes</label>

                                <input type="radio" class="custom-radio"
                                    name="transferring-exemption-previous-residence"
                                    id="transferring-exemption-previous-residence-0" :value="false"
                                    v-model="formFields.transferring_exemption_previous_residence" required>
                                <label for="transferring-exemption-previous-residence-0">No</label>
                            </fieldset>
                        </div>

                        <div class="element-container" v-if="anyExemptionSelectedBesidesGeneral">
                            <span class="form-label form-label-required">
                                Are you transferring a tax limitation?
                            </span>
                            <fieldset>
                                <input type="radio" class="custom-radio" name="transferring-tax-limitation"
                                    id="transferring-tax-limitation-1" :value="true"
                                    v-model="formFields.transferring_tax_limitation" required
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="transferring-tax-limitation-1">Yes</label>
                                <input type="radio" class="custom-radio" name="transferring-tax-limitation"
                                    id="transferring-tax-limitation-0" :value="false"
                                    v-model="formFields.transferring_tax_limitation" required>
                                <label for="transferring-tax-limitation-0">No</label>
                            </fieldset>
                        </div>

                        <!-- Previous Residence -->
                        <!-- TODO: currently the formsite version displays this all the time. Is that correct? -->
                        <div class="subsection-header">What was the address of your previous residence?</div>
                        <AddressFields :element-required-handler="elementRequiredHandler"
                            field-code="previous_residence_address" text="Previous Residence" :show-county="true"
                            v-model:address="formFields.previous_residence_address_line_1"
                            v-model:unit-number="formFields.previous_residence_unit_number"
                            v-model:city="formFields.previous_residence_city"
                            v-model:state="formFields.previous_residence_state"
                            v-model:postal-code="formFields.previous_residence_postal_code"
                            v-model:county="formFields.previous_residence_county" />

                    </ApplicationSection>
                    <!-- end section 1-->

                    <ApplicationSection
                        title="SECTION 2: Property Owner/Applicant (Provide information for additional property owners in Section 5.)">
                        <!-- property owner type (single, married, other) -->
                        <div class="element-container">
                            <span class="form-label form-label-required">
                                Select One
                            </span>
                            <fieldset>
                                <div class="list-item">
                                    <input type="radio" class="custom-radio" name="property-owner-type"
                                        id="property-owner-type-single" :value="OwnerType.Single" required
                                        v-model="formFields.property_owner_type_1"
                                        @invalid.capture.prevent="elementRequiredHandler">
                                    <label for="property-owner-type-single">Single Adult</label>
                                </div>

                                <div class="list-item">
                                    <input type="radio" class="custom-radio" name="property-owner-type"
                                        id="property-owner-type-married-couple" :value="OwnerType.Married" required
                                        v-model="formFields.property_owner_type_1">
                                    <label for="property-owner-type-married-couple">Married Couple</label>
                                </div>

                                <div class="list-item">
                                    <input type="radio" class="custom-radio" name="property-owner-type"
                                        id="property-owner-type-other" :value="OwnerType.Other" required
                                        v-model="formFields.property_owner_type_1">
                                    <label for="property-owner-type-other">Other (e.g., individual who owns the property
                                        with others)
                                    </label>
                                </div>
                            </fieldset>
                        </div>
                        <h3 class="subsection-header">Property Owner 1</h3>
                        <OwnerFields :instanceKey="instanceKey" :owner-number="1" :accept-file-types="ACCEPT_FILE_TYPES"
                            :max-date-string="todayISOString" :file-input-change-handler="fileInputChangeHandler"
                            :require-drivers-license-default="true" :require-birthdate="true"
                            :element-required-handler="elementRequiredHandler" :customerID="form.customer_id"
                            :api_key="form.api_key" v-model:name="formFields.property_owner_name_1"
                            v-model:birthdate="formFields.property_owner_birthdate_1"
                            v-model:email="formFields.property_owner_email_1"
                            v-model:license-number="formFields.property_owner_id_number_1"
                            v-model:percent-ownership-interest="formFields.property_owner_percent_ownership_interest_1"
                            v-model:phone="formFields.property_owner_phone_1"
                            v-model:affirm_id_property_address_match="formFields.affirm_id_property_address_match"
                            v-model:property-address-line1="formFields.property_address_line_1"
                            v-model:property-city="formFields.property_city"
                            v-model:property-state="formFields.property_state"
                            v-model:property-postal-code="formFields.property_postal_code" />
                        <div id="property-owner-2-container" v-if="showPropertyOwner2">
                            <h3 class="subsection-header">Property Owner 2</h3>
                            <OwnerFields :instanceKey="instanceKey" :owner-number="2"
                                :accept-file-types="ACCEPT_FILE_TYPES"
                                :file-input-change-handler="fileInputChangeHandler" :max-date-string="todayISOString"
                                :require-birthdate="formFields.property_owner_type_1 === 'married'"
                                :element-required-handler="elementRequiredHandler" :customerID="form.customer_id"
                                :api_key="form.api_key" v-model:name="formFields.property_owner_name_2"
                                v-model:birthdate="formFields.property_owner_birthdate_2"
                                v-model:email="formFields.property_owner_email_2"
                                v-model:license-number="formFields.property_owner_id_number_2"
                                v-model:percent-ownership-interest="formFields.property_owner_percent_ownership_interest_2" />
                        </div>

                        <div id="application-address-container" class="application-address-container">
                            <h3 class="subsection-header">Applicant Mailing Address</h3>

                            <div class="element-container">
                                <span class="form-label form-label-required">
                                    Is the applicant mailing address different than the physical address?
                                </span>
                                <fieldset>
                                    <input type="radio" class="custom-radio" name="applicant-mailing-address-different"
                                        id="applicant-mailing-address-different-1" :value="true" required
                                        v-model="formFields.applicant_mailing_address_different"
                                        @invalid.capture.prevent="elementRequiredHandler">
                                    <label for="applicant-mailing-address-different-1">Yes</label>

                                    <input type="radio" class="custom-radio" name="applicant-mailing-address-different"
                                        id="applicant-mailing-address-different-0" :value="false" required
                                        v-model="formFields.applicant_mailing_address_different">
                                    <label for="applicant-mailing-address-different-0">No</label>
                                </fieldset>
                            </div>

                            <AddressFields :element-required-handler="elementRequiredHandler" text="Applicant Mailing"
                                field-code="applicant" v-if="formFields.applicant_mailing_address_different"
                                :required="formFields.applicant_mailing_address_different"
                                v-model:address="formFields.applicant_mail_line_1"
                                v-model:unit-number="formFields.applicant_mail_unit_number"
                                v-model:city="formFields.applicant_mail_city"
                                v-model:state="formFields.applicant_mail_state"
                                v-model:postal-code="formFields.applicant_mail_postal_code" />
                        </div>


                    </ApplicationSection>
                    <!-- end section 2-->

                    <ApplicationSection title="SECTION 3: Property Information">
                        <div class="element-container">
                            <label class="form-label form-label-required" for="property-date-acquired">
                                Date you acquired this property
                            </label>
                            <DateComboBox v-model="formFields.property_date_acquired"
                                :element-required-handler="elementRequiredHandler" :required="true"
                                :id="'property-date-acquired'" />
                        </div>


                        <div class="element-container">
                            <label class="form-label form-label-required" for="property-date-occupied">
                                Date you began occupying this property as your principal residence
                            </label>
                            <DateComboBox v-model="formFields.property_date_occupied"
                                :element-required-handler="elementRequiredHandler" :required="true"
                                :id="'property-date-occupied'" />
                        </div>

                        <AddressFields :element-required-handler="elementRequiredHandler" field-code="property"
                            text="Property" :show-county="false" :allow-po-box="false" :required="true"
                            v-model:address="formFields.property_address_line_1"
                            v-model:unit-number="formFields.property_unit_number"
                            v-model:city="formFields.property_city" v-model:state="formFields.property_state"
                            v-model:postal-code="formFields.property_postal_code" />

                        <div class="element-container">
                            <label for="property-legal-description">Legal Description (if known)</label>
                            <input type="text" id="property-legal-description"
                                v-model="formFields.property_legal_description" maxlength="255">
                        </div>

                        <div class="element-container">
                            <span class="form-label form-label-required">Is the applicant identified on deed or other
                                recorded instrument?</span>
                            <fieldset>
                                <input type="radio" class="custom-radio" name="applicant-identified-on-deed"
                                    id="applicant-identified-on-deed-1" :value="true" data-on="1" required
                                    v-model="formFields.applicant_identified_on_deed"
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="applicant-identified-on-deed-1">Yes</label>

                                <input type="radio" class="custom-radio" name="applicant-identified-on-deed"
                                    id="applicant-identified-on-deed-0" :value="false" data-on="0" required
                                    v-model="formFields.applicant_identified_on_deed">
                                <label for="applicant-identified-on-deed-0">
                                    No - Required documentation must be provided. (see <a
                                        href="#important-information">Important
                                        information</a>)
                                </label>

                                <div class="public-form-additional-info-conditional">
                                    <label for="court-filing-number" class="form-label">
                                        Court record/filing number on recorded deed or other recorded instrument, if
                                        available:
                                    </label>
                                    <input type="text" v-model="formFields.court_filing_number" id="court-filing-number"
                                        maxlength="255" spellcheck="false">
                                </div>

                                <div class="public-form-additional-info-conditional-off"
                                    v-if="!formFields.applicant_identified_on_deed">
                                    <FileUploader id="applicant-not-on-deed-documentation-file"
                                        :required="!formFields.applicant_identified_on_deed" :disabled="!isFormLoaded"
                                        :accept="ACCEPT_FILE_TYPES" :elementRequiredHandler="elementRequiredHandler"
                                        @change="uploadFiles">
                                        <template #label>
                                            <AdditionalDocumentationRequired />
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus
                                        containerID="applicant-not-on-deed-documentation-file" />
                                </div>
                            </fieldset>
                        </div>

                        <div class="element-container">
                            <span class="form-label form-label-required">
                                Is the property for which this application is submitted an heir property (see <a
                                    href="#important-information">Important information</a>)?
                            </span>
                            <fieldset>
                                <input type="radio" class="custom-radio" name="is-heir-property"
                                    v-model="formFields.is_heir_property" id="is-heir-property-1" :value="true" required
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="is-heir-property-1">Yes</label>
                                <input type="radio" class="custom-radio" name="is-heir-property"
                                    v-model="formFields.is_heir_property" id="is-heir-property-0" :value="false"
                                    required>
                                <label for="is-heir-property-0">No</label>
                            </fieldset>
                        </div>

                        <div id="heir-property-detail-container" class="element-container"
                            v-if="formFields.is_heir_property">
                            <span class="form-label form-label-required">Do other heir property owners occupy the
                                property?</span>
                            <fieldset>
                                <input type="radio" class="custom-radio" name="heir-property-owners-occupy-property"
                                    id="heir-property-owners-occupy-property-1" :value="true" data-on="1" required
                                    v-model="formFields.heir_property_owners_occupy_property"
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="heir-property-owners-occupy-property-1">Yes (affidavits required)</label>

                                <input type="radio" class="custom-radio" name="heir-property-owners-occupy-property"
                                    id="heir-property-owners-occupy-property-0" :value="false" required
                                    v-model="formFields.heir_property_owners_occupy_property">
                                <label for="heir-property-owners-occupy-property-0">No</label>

                                <div class="public-form-additional-info-conditional">
                                    <FileUploader id="heir-property-owner-occupies-property-documentation-file"
                                        :required="getRequiredAttrValue(formFields.heir_property_owners_occupy_property)"
                                        :disabled="!isFormLoaded" :accept="ACCEPT_FILE_TYPES"
                                        :elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
                                        <template #label>
                                            <AdditionalDocumentationRequired />
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus
                                        containerID="heir-property-owner-occupies-property-documentation-file" />
                                </div>
                            </fieldset>
                        </div>


                        <div class="element-container">
                            <span class="form-label form-label-required">Is this property a manufactured home?</span>
                            <fieldset>
                                <input type="radio" class="custom-radio" name="property-manufactured-home"
                                    id="property-manufactured-home-1" v-model="formFields.property_manufactured_home"
                                    :value="true" data-on="1" required
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="property-manufactured-home-1">Yes</label>

                                <input type="radio" class="custom-radio" name="property-manufactured-home"
                                    id="property-manufactured-home-0" v-model="formFields.property_manufactured_home"
                                    :value="false" required>
                                <label for="property-manufactured-home-0">No</label>

                                <div class="public-form-additional-info-conditional">
                                    <label for="manufactured-home-make" class="form-label">Manufactured Home
                                        Make</label>
                                    <input type="text" id="manufactured-home-make"
                                        v-model="formFields.manufactured_home_make" maxlength="255" spellcheck="false">
                                </div>

                                <div class="public-form-additional-info-conditional">
                                    <label for="manufactured-home-model" class="form-label">Model</label>
                                    <input type="text" id="manufactured-home-model"
                                        v-model="formFields.manufactured_home_model" maxlength="255" spellcheck="false">
                                </div>

                                <div class="public-form-additional-info-conditional">
                                    <label for="manufactured-home-id" class="form-label">ID Number</label>
                                    <input type="text" id="manufactured-home-id"
                                        v-model="formFields.manufactured_home_id" maxlength="255" spellcheck="false">
                                </div>

                                <div class="public-form-additional-info-conditional">
                                    <FileUploader id="property-manufactured-home-file"
                                        :required="formFields.property_manufactured_home === true"
                                        :disabled="!isFormLoaded" :accept="ACCEPT_FILE_TYPES"
                                        :elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
                                        <template #label>
                                            <AdditionalDocumentationRequired>
                                                <p>Manufactured homeowners must provide:</p>
                                                <ul>
                                                    <li>a copy of the Texas Department of Housing and Community Affairs
                                                        statement of ownership
                                                        showing
                                                        that the applicant is the owner of the manufactured home;</li>
                                                    <li>a copy of the sales purchase agreement, other applicable
                                                        contract or
                                                        agreement or payment
                                                        receipt showing that the applicant is the purchaser of the
                                                        manufactured
                                                        home; or</li>
                                                    <li>a sworn affidavit (see Form 50-114-A) by the applicant
                                                        indicating that:
                                                        <ol>
                                                            <li>the applicant is the owner of the manufactured home;
                                                            </li>
                                                            <li>the seller of the manufactured home did not provide the
                                                                applicant with the
                                                                applicable
                                                                contract
                                                                or agreement; and</li>
                                                            <li>the applicant could not locate the seller after making a
                                                                good
                                                                faith effort.</li>
                                                        </ol>

                                                    </li>
                                                </ul>
                                            </AdditionalDocumentationRequired>
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus containerID="property-manufactured-home-file" />
                                </div>
                            </fieldset>
                        </div>

                        <div class="element-container">
                            <span class="form-label form-label-required">
                                Is any portion of the property for which you are claiming a residence homestead
                                exemption
                                income producing?
                            </span>
                            <fieldset>
                                <input type="radio" class="custom-radio" name="property-produces-income"
                                    id="property-produces-income-1" v-model="formFields.property_produces_income"
                                    :value="true" data-on="1" required
                                    @invalid.capture.prevent="elementRequiredHandler">
                                <label for="property-produces-income-1">Yes</label>

                                <input type="radio" class="custom-radio" name="property-produces-income"
                                    id="property-produces-income-0" v-model="formFields.property_produces_income"
                                    :value="false" required>
                                <label for="property-produces-income-0">No</label>

                                <div class="public-form-additional-info-conditional">
                                    <label class="form-label" for="percent-producing-income">
                                        Percentage of the property that is income producing
                                    </label>
                                    <input id="percent-producing-income" v-model="formFields.percent_producing_income"
                                        type="number" min="0" max="100" step="1"> %
                                </div>
                            </fieldset>
                        </div>

                        <div class="element-container">
                            <label class="form-label" for="property-acres-occupied">
                                Number of acres (or fraction of an acre, not to exceed 20 acres) you own and occupy as
                                your
                                principal residence
                            </label>
                            <input id="property-acres-occupied" v-model="formFields.property_acres_occupied"
                                type="number" min="0" max="20" step="any"> acres
                        </div>

                    </ApplicationSection>
                    <!-- end section 3-->

                    <ApplicationSection title="SECTION 4: Waiver of Required Documentation">
                        <div v-if="formFields.affirm_id_property_address_match === true"
                            class="public-container public-button-submit p-3">
                            Not required. You've provided your required documentation above.
                        </div>
                        <div v-else>
                            <div>
                                <strong class="form-label">
                                    Indicate if you are exempt from the requirement to provide a copy of your
                                    driver license or state-issued personal identification certificate.
                                </strong>
                            </div>
                            <div class="element-container" id="waive-reason-resident-of-facility container">
                                <input type="checkbox" class="custom-checkbox" id="waive-reason-resident-of-facility"
                                    v-model="formFields.waive_reason_resident_of_facility">
                                <label for="waive-reason-resident-of-facility">
                                    I am a resident of a facility that provides services related to health, infirmity or
                                    aging.
                                </label>

                                <div class="public-form-additional-info-conditional">
                                    <label class="form-label form-label-required" for="facility-name-address">
                                        Facility Name and Address
                                    </label>
                                    <textarea id="facility-name-address" v-model="formFields.facility_name_address"
                                        :required="formFields.waive_reason_resident_of_facility"
                                        @invalid.capture.prevent="elementRequiredHandler"
                                        :maxlength="MAX_NOTE_LENGTH"></textarea>
                                </div>
                            </div>

                            <div class="element-container">
                                <input type="checkbox" class="custom-checkbox"
                                    id="waive-reason-participator-address-confidential-program"
                                    v-model="formFields.waive_reason_participator_address_confidential_program">
                                <label for="waive-reason-participator-address-confidential-program">
                                    I am certified for participation in the address confidentiality program administered
                                    by
                                    the
                                    Office of the Texas Attorney General under Code of Criminal
                                </label>
                            </div>

                            <div>
                                <strong class="form-label">
                                    Indicate if you request that the Chief Appraiser waive the requirement that the
                                    property address for exemption corresponds to your driver license or state-issued
                                    personal identification certificate address:
                                </strong>
                            </div>
                            <div class="element-container">
                                <input type="checkbox" class="custom-checkbox" id="waive-reason-active-duty"
                                    v-model="formFields.waive_reason_active_duty">
                                <label for="waive-reason-active-duty">
                                    I am an active duty U.S. armed services member or the spouse of an active duty
                                    member.
                                </label>

                                <div class="element-container public-form-additional-info-conditional"
                                    style="margin: 1em auto auto 1.75em">
                                    <FileUploader id="waive-reason-active-duty-military-id-file"
                                        :required="formFields.waive_reason_active_duty" :disabled="!isFormLoaded"
                                        :accept="ACCEPT_FILE_TYPES" :elementRequiredHandler="elementRequiredHandler"
                                        @change="uploadFiles">
                                        <template #label>
                                            <label class="form-label form-label-required"
                                                for="waive-reason-active-duty-military-id-file">
                                                Upload Military ID
                                            </label>
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus
                                        containerID="waive-reason-active-duty-military-id-file" />
                                </div>

                                <div class="element-container public-form-additional-info-conditional"
                                    style="margin: 1em auto auto 1.75em">
                                    <FileUploader id="waive-reason-active-duty-utility-bill-file"
                                        :required="formFields.waive_reason_active_duty" :disabled="!isFormLoaded"
                                        :accept="ACCEPT_FILE_TYPES" :elementRequiredHandler="elementRequiredHandler"
                                        @change="uploadFiles">
                                        <template #label>
                                            <label class="form-label form-label-required"
                                                for="waive-reason-active-duty-utility-bill-file">
                                                Upload Utility Bill
                                            </label>
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus
                                        containerID="waive-reason-active-duty-utility-bill-file" />
                                </div>
                            </div>

                            <div class="element-container">
                                <input type="checkbox" class="custom-checkbox" id="waive-reason-special-drivers-license"
                                    v-model="formFields.waive_reason_special_drivers_license">
                                <label for="waive-reason-special-drivers-license">
                                    I hold a driver license issued under Transportation Code Section 521.121(c) or
                                    521.1211. Attached is a copy of the application for that license.
                                </label>

                                <div class="public-form-additional-info-conditional">
                                    <FileUploader id="waive-reason-special-drivers-documentation-file"
                                        :required="formFields.waive_reason_special_drivers_license"
                                        :disabled="!isFormLoaded" :accept="ACCEPT_FILE_TYPES"
                                        :elementRequiredHandler="elementRequiredHandler" @change="uploadFiles">
                                        <template #label>
                                            <AdditionalDocumentationRequired />
                                        </template>
                                    </FileUploader>
                                    <ApplicationFileUploadStatus
                                        containerID="waive-reason-special-drivers-documentation-file" />
                                </div>
                            </div>
                        </div>
                    </ApplicationSection>
                    <!-- end section 4-->

                    <ApplicationSection title="SECTION 5: Additional Information">
                        <div class="element-container">
                            <label for="additional-info">Provide Additional Information Here (If any)</label>
                            <textarea id="additional-info" v-model="formFields.additional_info"
                                :maxlength="MAX_NOTE_LENGTH"></textarea>
                        </div>

                        <div class="element-container">
                            <label for="other-owned-properties">
                                If you own other residential property in Texas, please list the county(ies) of location
                            </label>
                            <textarea id="other-owned-properties" v-model="formFields.other_owned_properties"
                                :maxlength="MAX_NOTE_LENGTH"></textarea>
                        </div>
                    </ApplicationSection>
                    <!-- end section 5-->

                    <ApplicationSection title="SECTION 6: Affirmation and Signature">
                        <p>
                            <strong>
                                I understand if I make a false statement on this form, I could be found guilty of a
                                Class A misdemeanor or a state jail felony under Penal Code Section 37.10.
                            </strong>
                        </p>

                        <div class="element-container">
                            <label for="authorized-print-name" class="form-label form-label-required">
                                Property Owner/Authorized Representative Name
                            </label>
                            <input type="text" @invalid.capture.prevent="elementRequiredHandler"
                                v-model="formFields.authorized_print_name" id="authorized-print-name" maxlength="255"
                                required>
                        </div>

                        <div class="element-container">
                            <label for="title-authorization"
                                class="form-label form-label-required">Title/Authorization</label>
                            <select id="title-authorization" v-model="formFields.title_authorization" required
                                @invalid.capture.prevent="elementRequiredHandler">
                                <option></option>
                                <option value="Property Owner">Property Owner</option>
                                <option value="Authorized Agent">Authorized Agent</option>
                            </select>
                        </div>

                        <p>By signing this electronic form, I swear or affirm the following:
                        <ol>
                            <li>that each fact contained in this application is true and correct;</li>
                            <li>that I/the property owner meet(s) the qualifications under Texas law for
                                the residence homestead exemption for which I am applying; and</li>
                            <li>that I/the property owner do(es) not claim an exemption on another residence
                                homestead or claim a residence homestead exemption on a residence homestead
                                outside Texas.</li>
                        </ol>
                        </p>
                        <div class="element-container">
                            <span class="form-label form-label-required">
                                Signature of Property Owner/Applicant or Authorized Representative
                            </span>
                            <Signature v-model:signature_of_applicant="formFields.signature_of_applicant" />
                        </div>
                    </ApplicationSection>
                    <!-- end section 6-->

                    <ApplicationFootnotes />

                    <SubmitButtonContainer v-if="istValidUUID" />

                    <Message severity="error" :closable="false" class="m-2 mb-5" v-if="istValidUUID === false">
                        <i class="fas fa-exclamation-triangle"></i>
                        {{ NOT_VALID_UUID_ERR_MESSAGE }}
                    </Message>

                    <ImportantInformation />

                    <ApplicationFooter>
                        <p>For additional copies of the original TX Comptroller version of this form:
                            <a href="https://comptroller.texas.gov/forms/50-114.pdf" rel="noopener">
                                https://comptroller.texas.gov/forms/50-114.pdf
                            </a>
                        </p>
                    </ApplicationFooter>
                </form>
            </div>
            <div class="content text-center" v-else>
                <ApplicationSection title="Form 50-114">
                    <p>
                        Your submission was successful.
                        <br>
                        <br>
                        Application ID: <b>{{ confirmationNumber }}</b>
                    </p>
                    <p>
                        <a href="#" @click="refreshForm()">Go back</a>
                    </p>
                </ApplicationSection>
            </div>
        </div>
    </main>
</template>

<script setup lang="ts">
import ApplicationFootnotes from "@/components/ApplicationForm/tx/ApplicationFootnotes.vue"
import ApplicationSection from "@/components/ApplicationForm/ApplicationSection.vue"
import AdditionalDocumentationRequired from "@/components/ApplicationForm/AdditionalDocumentationRequired.vue"
import OwnerFields from "@/components/ApplicationForm/OwnerFields.vue"
import AddressFields from "@/components/ApplicationForm/AddressFields.vue"
import ApplicationFileUploadStatus from "@/components/ApplicationForm/ApplicationFileUploadStatus.vue"
import ImportantInformation from "@/components/ApplicationForm/tx/ImportantInformation.vue"
import SubmitButtonContainer from "@/components/ApplicationForm/SubmitButtonContainer.vue"
import ApplicationFooter from "@/components/ApplicationForm/ApplicationFooter.vue"
import Signature from "@/components/ApplicationForm/Signature.vue"
import DateComboBox from "@/components/Shared/DateComboBox.vue"
import { computed, onMounted, ref, watch } from "vue"
import { generateUUIDv4, hidePFGetHelpButton } from "@/helpers/common"
import { getFileExtension, getFileSizeText, isValidFileType } from "@/helpers/files"
import { useApplicationForm } from "@/stores/applicationForm"
import { OwnerType, type NamForm } from '@/helpers/interface/applicationForm';
import type { AxiosError } from "axios"
import { MAX_NOTE_LENGTH, getApiErrorMessage, setPageTitle } from "@/helpers/common"
import { toast } from "@/helpers/toast"
import { useRoute } from "vue-router"
import { useAPI } from "@/helpers/services/api"
import Message from 'primevue/message'
import { isValidEmail } from "@/helpers/regex"
import FileUploader from "@/components/Shared/FileUploader.vue"
import dayjs from "dayjs"

const INVALID_EMAIL_MESSAGE = "You must enter a valid email address. Example: name@example.com"
const publicAPI = useAPI({ authGuard: false })
const route = useRoute()
sessionStorage.setItem("state", "tx")
const storeApplicationForm = useApplicationForm()
const ACCEPT_FILE_TYPES = ".png, .jpg, .jpeg, .bmp, .gif, .pdf"
const today = new Date()
const todayISOString = today.toISOString().substring(0, 10)

const formSubmitted = ref(false)
const formFields = computed(() => (storeApplicationForm.fields))
const uploadState = computed(() => (storeApplicationForm.uploadState))
let taxYearsAvailable: number[] = []
const now = new Date()
const currentYear = now.getFullYear()
const startYear = now.getMonth() === 0 ? currentYear : currentYear + 1
for (let index: number = startYear; index >= startYear - 3; index--) {
    taxYearsAvailable.push(index)
}
/* this key must be included in all HTTP requests to the server as a header
    for file uploads and form submission itself
*/
const instanceKey = ref("")
instanceKey.value = generateUUIDv4()
const confirmationNumber = ref(0)
const formID = route.params.form_id as string
const form = ref({
    id: formID,
    customer_id: '',
    name: '',
    state: '',
    fips_code: '',
    api_key: '',
} as NamForm)

const isFormLoaded = computed(() => {
    return form.value.customer_id !== ''
})

const NOT_VALID_UUID_ERR_MESSAGE = "Error: The form cannot be submitted due to an invalid ID. Please report this issue to the county."
const istValidUUID = ref<Boolean | null>(null)
const loadNamForm = async (formID: string) => {
    istValidUUID.value = true
    try {
        const response = await publicAPI.get(`/applications/form/${formID}`)
        form.value = response.data
    } catch (error) {
        istValidUUID.value = false
        toast.error(NOT_VALID_UUID_ERR_MESSAGE, {
            duration: 0,
            dismissible: false,
            position: "top-right"
        });
    }
}

const getRequiredAttrValue = (value: boolean | null): boolean => (value === true)

const showDeceasedSpouseFields = computed(() => {
    const data = formFields.value;
    return data.exemption_age_65_plus ||
        data.exemption_disabled_veteran ||
        data.exemption_surviving_spouse_armed_services ||
        data.exemption_surviving_spouse_first_responder ||
        data.exemption_donated_residence
});

const showPropertyOwner2 = computed(() => {
    if (!formFields.value.property_owner_type_1)
        return false;
    return [OwnerType.Married, OwnerType.Other].includes(formFields.value.property_owner_type_1)
});


const anyExemptionSelectedBesidesGeneral = computed(() => {
    const data = formFields.value;
    return data.exemption_disabled_person ||
        data.exemption_age_65_plus ||
        data.exemption_disabled_veteran ||
        data.exemption_surviving_spouse_armed_services ||
        data.exemption_surviving_spouse_first_responder ||
        data.exemption_donated_residence
})

const atLeastOneExemptionSelected = computed(() => {
    const data = formFields.value
    return data.exemption_general ||
        data.exemption_disabled_person ||
        data.exemption_age_65_plus ||
        data.exemption_disabled_veteran ||
        data.exemption_surviving_spouse_armed_services ||
        data.exemption_surviving_spouse_first_responder ||
        data.exemption_donated_residence
})


const fileInputChangeHandler = (e: Event, isDriversLicense: boolean = false) => {
    const maxMegaBytes = isDriversLicense ? 10 : 20
    const MAX_FILE_SIZE_BYTES = maxMegaBytes * 1024 * 1024  // MB converted by bytes
    const input = e.target as HTMLInputElement
    if (!input.files)
        return;
    const selectedFile = input.files[0]

    if (selectedFile) {
        if (!isValidFileType(selectedFile.name, ACCEPT_FILE_TYPES.split(","))) {
            const extension = getFileExtension(selectedFile.name)
            alert(`Files of type '${extension.toUpperCase()}'' may not be uploaded.`)
            input.value = "";
            return;
        }
        if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
            const fileSizeText = getFileSizeText(selectedFile.size)
            const maxSizeText = getFileSizeText(MAX_FILE_SIZE_BYTES)
            alert(`The selected file is ${fileSizeText}, which is larger than the maximum allowed (${maxSizeText}).`)
            input.value = "";
            return;
        }
        if (selectedFile.size === 0) {
            alert("The selected file contains no data and may not be uploaded.");
            input.value = "";
            return;
        }
    }
}

const elementRequiredHandler = (e: Event) => {
    const input = e.target as HTMLInputElement;
    let container: any = input.closest("fieldset");
    if (!container) {
        container = input.parentNode as HTMLElement;
    }
    if (!container)
        throw "Unable to process required element because it's not attached to the DOM"

    let displayText = "This field is required";
    switch (input.type) {
        case "radio":
            displayText = "Please choose an option"
            break;
        case "file":
            displayText = "You must upload a file"
            break;
        case "tel":
            displayText = input.getAttribute("title")?.toString() || "You must follow this format (555-123-4567)"
            break;
        case "email":
            displayText = input.getAttribute("title")?.toString() || INVALID_EMAIL_MESSAGE
            break;
        case "number":
            displayText = `Value must be a number between ${input.getAttribute("min")} and ${input.getAttribute("max")}`
            if ((~~input.step) > 1) {
                displayText += ` in increments of ${input.step}`
            }
            break;
    }
    displayInvalidField(container, displayText)
}

const displayInvalidField = (target: HTMLElement, text: string) => {
    const INVALID = "public-field-invalid";

    target.setAttribute("data-invalid-text", text)
    target.classList.add(INVALID);
    target.scrollIntoView()

    setTimeout(() => {
        target.setAttribute("data-invalid-text", "")
        target.classList.remove(INVALID)
    }, 5000);
}

const convertBase64ToFile = (base64: string, filename: string, contentType: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new File([byteArray], filename, { type: contentType })
}

const getEffectiveDate = () => {
    return (dayjs(formFields.value.start_year_of_exemption).isValid()) ? `${formFields.value.start_year_of_exemption}-01-01` : null
}

const getTaxYears = (): number[] => {
    const years: number[] = []
    const endYear = taxYearsAvailable[0]

    const startYearRaw = formFields.value.start_year_of_exemption as number

    try {

        // Validate and parse the dropdown value
        const isValidYear = (value: any): boolean => {
            return (
                typeof value === "string" &&
                /^\d{1,4}$/.test(value.trim()) && // Only digits (up to 4)
                Number(value) >= 0 && 
                Number(value) <= endYear
            )
        }

        const startYear =
            isValidYear(startYearRaw)
                ? Math.floor(Number(startYearRaw))
                : endYear


        // Generate the tax years
        for (let year = startYear; year <= endYear; year++) {
            years.push(year)
        }

        return years
    } catch (error) {
        return [endYear]
    }
}

const uploadSignature = async (base64Signature: string) => {
    return await storeApplicationForm.uploadFile(
        instanceKey.value,
        convertBase64ToFile(base64Signature, "signature.png", "image/png"),
        form.value.customer_id!,
        "application_attachments",
        "signature_of_applicant"
    )
}

const createSubmissionPayload = async () => {
    const payload = { ...formFields.value } as any

    Object.keys(payload).forEach(key => {
        // convert all dates (from PrimeVue datepicker) to ISO strings
        if (payload[key] instanceof Date) {
            if (isNaN(+payload[key])) {
                payload[key] = null
            } else {
                payload[key] = payload[key].toISOString().substring(0, 10)
            }
        }
        if (payload[key] === "") {
            payload[key] = null;
        }
    })

    const idSeed = instanceKey.value.slice(0, 4)

    payload['tru_id'] = instanceKey.value
    payload['application_id'] = parseInt(idSeed, 16).toString()
    payload['form_id'] = form.value.id
    payload['effective_date'] = getEffectiveDate()
    payload['tax_years'] = getTaxYears()
    delete payload['start_year_of_exemption']


    const sigUpl = await uploadSignature(payload['signature_of_applicant'])
    payload['signature_of_applicant'] = sigUpl?.id

    return payload
}

const submitFormHandler = async () => {

    const startYear = formFields.value.start_year_of_exemption

    // Verify if an exemption year is selected 
    // and ensure it is a valid year.
    if (!startYear || !dayjs(startYear).isValid()) {
        displayInvalidField(
            document.getElementById("start-year-of-exemption")?.parentElement as HTMLElement,
            "Please reselect the first year of exemption"
        )
        return
    }
    // Verify that the total number of tax years matches the formula:
    // (current year - selected year + 1).
    // For example, if the selected year is 2023, the generated tax years 
    // should be [2023, 2024, 2025], totaling 3 years. 
    // This should align with the calculation: (2025 - 2023 + 1).
    const expectedYears = taxYearsAvailable[0] - startYear + 1
    if (getTaxYears().length !== expectedYears) {
        displayInvalidField(
            document.getElementById("start-year-of-exemption")?.parentElement as HTMLElement,
            "Please reselect the first year of exemption"
        )
        return
    }

    if (!atLeastOneExemptionSelected.value) {
        const target = document.getElementById("general-exemption-container") as HTMLElement
        displayInvalidField(target, "You must choose at least one type of exemption")
        return;
    }
    if (formFields.value.signature_of_applicant.length === 0) {
        const target = document.getElementById("signature-container") as HTMLElement
        displayInvalidField(target, "Your signature is required")
        return;
    }
    if (!isValidEmail(formFields.value.property_owner_email_1)) {
        const input = document.getElementById("property-owner-email-1") as HTMLElement
        let container: any = input.closest("fieldset");
        if (!container) container = input.parentNode as HTMLElement;
        if (container) {
            displayInvalidField(container, INVALID_EMAIL_MESSAGE)
            return;
        }
    }
    if (showPropertyOwner2.value && formFields.value.property_owner_email_2?.trim() && !isValidEmail(formFields.value.property_owner_email_2)) {
        const input = document.getElementById("property-owner-email-2") as HTMLElement
        let container: any = input.closest("fieldset");
        if (!container) container = input.parentNode as HTMLElement;
        if (container) {
            displayInvalidField(container, INVALID_EMAIL_MESSAGE)
            return;
        }
    }

    if (uploadState.value > 0) {
        toast.info("Hold tight, we're processing your upload.")
        return;
    }
    if (formFields.value.affirm_id_property_address_match === false) {
        if (
            !(
                formFields.value.waive_reason_resident_of_facility ||
                formFields.value.waive_reason_participator_address_confidential_program ||
                formFields.value.waive_reason_active_duty ||
                formFields.value.waive_reason_special_drivers_license
            )
        ) {
            const target = document.getElementById("waive-reason-resident-of-facility container") as HTMLElement
            displayInvalidField(target, "You must check at least one of the waivers")
            return;
        }
    }
    storeApplicationForm.getOrSetFormLoading(true)
    const payload = await createSubmissionPayload()

    try {
        const resp = await publicAPI.post(
            `/applications/${form.value.fips_code}`,
            payload,
            {
                "headers": {
                    "Content-Type": "application/json",
                    "X-Api-Key": form.value.api_key,
                }
            }
        )
        if (resp.data && resp.data.application_id) {
            confirmationNumber.value = resp.data.application_id as number
        }
        formSubmitted.value = true
        AppState.clear()
    } catch (error) {
        toast.error(getApiErrorMessage(error as AxiosError))
    }
    storeApplicationForm.getOrSetFormLoading(false)
}


const uploadFiles = async (e: Event) => {
    if (!isFormLoaded.value) {
        await loadNamForm(formID)
    }
    if (!isFormLoaded.value) {
        throw new Error("Unauthorized form")
    }
    fileInputChangeHandler(e)
    const input = e.target as HTMLInputElement
    if (!input.files) return;
    const file = input.files && input.files[0]
    const id = input.getAttribute("id")?.toString() || ""
    await storeApplicationForm.uploadFile(
        instanceKey.value,
        file,
        form.value.customer_id!,
        "application_attachments",
        id,
        input
    )
}

const refreshForm = () => window.location.reload()


watch(() => formFields.value.exemption_donated_residence, (donatedResidence) => {
    /* If donated residence exemption is turned OFF, then 
        reset the percent disability rating. It may be invalid and
        could prevent submission. */
    if (!donatedResidence) {
        formFields.value.percent_disability_rating = null
    }
})

watch(() => formFields.value.property_owner_type_1, (ownerType) => {
    /* If owner type is changed to single, owner 2 fields are hidden.
        Reset the model values potentially invalid inputs don't prevent submission */
    if (ownerType === OwnerType.Single) {
        formFields.value.property_owner_name_2 = null
        formFields.value.property_owner_email_2 = null
        formFields.value.property_owner_birthdate_2 = null
        formFields.value.property_owner_id_number_2 = null
        formFields.value.property_owner_percent_ownership_interest_2 = null
        formFields.value.property_owner_phone_2 = null
    }
})


const AppState = {
    KEY: storeApplicationForm.TX_STATE_STORAGE_KEY,

    clear: () => {
        try {
            sessionStorage.removeItem(AppState.KEY)
        } catch (ex) {
            console.warn("Unable to clear form state from storage")
        }
    },

    save: () => {
        try {
            if (isFormLoaded.value) {
                sessionStorage.setItem(AppState.KEY, JSON.stringify(formFields.value));
            }
        } catch (ex) {
            console.warn("Unable to utilize storage to persist form state.")
        }
    },

    events: {
        windowBeforeUnload: () => {
            if (formSubmitted.value) {
                return;
            }
            return "Are you sure you want to leave this page? Your progress may be lost."
        }
    }

}


watch(formFields.value, AppState.save, { deep: true });

const isADAccountNumDisabled = computed(() => !!route.query.account_num)
onMounted(async () => {
    setPageTitle("Residence Homestead Exemption Application")
    formFields.value.appraisal_district_account_number = (route.query.account_num as string) || ""
    await loadNamForm(formID)
    window.onbeforeunload = AppState.events.windowBeforeUnload
    hidePFGetHelpButton()
})


</script>


<style>
@import "@/assets/public/public-base.css";
</style>
