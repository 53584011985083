
const queueOptions = [
    { value: "monitor", text: "Monitor (qualified)" },
    { value: "inbox", text: "Inbox" },
    { value: "questionnaire", text: "Questionnaire" },
    { value: "unqualified", text: "Unqualified" },
    { value: "snooze", text: "Snooze" },
    { value: "archive", text: "Archive As Sold" }
]

const flagsOptions = [
    { value: "Active Investigation", text: "Active Investigation" },
    { value: "Address Change", text: "Address Change" },
    { value: "Corporate Owner", text: "Corporate Owner" },
    { value: "Deceased", text: "Deceased" },
    { value: "DMV Conflict", text: "DMV Conflict" },
    { value: "Lives Elsewhere", text: "Lives Elsewhere" },
    { value: "Multiple HSE Within Jurisdiction", text: "Multi HSE in Jurisdiction" },
    { value: "Multiple HSE Out of Jurisdiction", text: "Multi HSE out of Jurisdiction" },
    { value: "Multiple Properties", text: "Multi Properties" },
    { value: "Other", text: "Other" },
    { value: "Out of County Mail", text: "Out of County Mail" },
    { value: "Out of State Mail", text: "Out of State Mail" },
    { value: "Rental", text: "Rental" },
    { value: "Situs and Mail Differ", text: "Situs and Mail Differ" },
    { value: "Vacancy", text: "Vacant / Inactive" },
    { value: "Voter Conflict", text: "Voter Conflict" },
]

const priorityOptions = [
    { value: "5", text: "5 - Very High" },
    { value: "4", text: "4 - High" },
    { value: "3", text: "3 - Medium" },
    { value: "2", text: "2 - Low" },
    { value: "1", text: "1 - Very Low" },
]

const applicantTypeOptions = [
    { value: "1", text: "Single Applicant" },
    { value: "2", text: "Multiple Applicants" },
    { value: "3", text: "Special Applicant" },
]

const finalOutcomeOptions = [
    { value: "sold", text: "Property Sold" },
    { value: "exemption removed", text: "Exemption Removed" },
]

const currentYear = new Date().getFullYear()
const unqualifiedStartYearOptions: any = []
for (let index = 2009; index <= (currentYear + 1); index++) {
    unqualifiedStartYearOptions.push({
        value: index, text: index
    });
}

const labelsOptions = [
    { value: "Demo Highlights", text: "Demo Highlights" },
    { value: "PO BOX ADRS", text: "PO BOX ADRS" },
    { value: "Reapplications", text: "Reapplications" },
    { value: "TrueReview", text: "TrueReview" },
]

const primaryViolationReasonOptions = [
    { value: "Deceased", text: "Deceased" },
    { value: "Multiple HSE", text: "Multiple HSE" },
    { value: "Non-Owner Occupied", text: "Non-Owner Occupied" },
    { value: "Rental", text: "Rental" },
    { value: "Other", text: "Other" },
]

const mailStatusOptions = [
    { value: 'created', text: '1 - Created' },
    { value: 'mailed', text: '2 - Mailed' },
    { value: 'completed', text: '3 - Completed' },
    { value: 'expired', text: '4 - Expired' },
    { value: 'mailing_issues', text: '5 - Mailing Issues' },
    { value: 'collections', text: '6 - Collections' },
];

const exemptionTypes = [
    { value: "homestead", text: "Homestead" },
    { value: "active_duty", text: "Active Duty" },
    { value: "veteran", text: "Veteran" },
    { value: "disabled", text: "Disabled" },
    { value: "disabled_veteran", text: "Disabled Veteran" },
    { value: "widow", text: "Widow" },
    { value: "senior", text: "Senior" },
    { value: "senior_freeze", text: "Senior Freeze" },
    { value: "senior_longterm", text: "Senior Long Term" },
    { value: "exempt_org", text: "Exempt Org" },
    { value: "homestead_cap", text: "Homestead Cap" },
    { value: "low_income", text: "Low Income" },
    { value: "other", text: "Other" },
];

const reportRecommendations = [
    { value: "unqualified", text: "Exemption Removal" }, 
    { value: "monitor", text: "Continue to Monitor" },
];

export {
    queueOptions,
    flagsOptions,
    priorityOptions,
    applicantTypeOptions,
    finalOutcomeOptions,
    unqualifiedStartYearOptions,
    labelsOptions,
    primaryViolationReasonOptions,
    mailStatusOptions,
    exemptionTypes,
    reportRecommendations,
}