<template>
    <div class="element-container" v-if="totalCandidateSelected === 1">
        <label for="saleDate" class="form-label">Sale Date</label>
        <DatePicker id="saleDate" class="d-flex" v-model="saleDate" />
    </div>

    <div class="element-container">
        <label for="addNote" class="form-label">
            Add Note
        </label>
        <Textarea id="addNote" v-model="optionalNote" :maxlength="MAX_NOTE_LENGTH" class="d-flex" />
    </div>
</template>

<script setup lang="ts">
import { MAX_NOTE_LENGTH } from '@/helpers/common'
import Textarea from 'primevue/textarea'
import DatePicker from 'primevue/datepicker'

const saleDate = defineModel<Date | null>("saleDate")
const optionalNote = defineModel<string | null>("optionalNote")

const { totalCandidateSelected } = defineProps({
    totalCandidateSelected: {
        type: Number
    }
})

</script>