import { computed, ref } from "vue"
import { defineStore } from "pinia";
import dayjs from "dayjs";
import { type ApplicationDataGA, type ApplicationForm, OwnerType } from "@/helpers/interface/applicationForm"
import axios from "axios"
import { getApiErrorMessage } from "@/helpers/common";
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api";
import type { Upload } from "@/helpers/interface/uploads";
import type { StateIdExtraction } from "@/helpers/interface/general";
import type { FormUploadFilesIDs, StorageKeys } from "@/helpers/interface/public";

export const useApplicationForm = defineStore("applicationForm", () => {
    const currentState: keyof StorageKeys = (sessionStorage.getItem("state") || "tx") as keyof StorageKeys
    const today = dayjs()

    const initialFormData: ApplicationForm = {
        appraisal_district_account_number: "",
        filing_late_application: null,
        start_year_of_exemption: null,
        exemption_general: false,
        exemption_disabled_person: false,
        exemption_age_65_plus: false,
        exemption_disabled_veteran: false,
        is_veteran_disability_permanent: null,
        exemption_surviving_spouse_armed_services: false,
        exemption_surviving_spouse_first_responder: false,
        exemption_donated_residence: false,
        percent_disability_rating: null,
        living_on_property: null,
        co_op_housing: null,
        co_op_housing_name: "",
        receiving_exemption_previous_residence: null,
        transferring_exemption_previous_residence: null,
        transferring_tax_limitation: null,
        previous_residence_address_line_1: null,
        previous_residence_unit_number: null,
        previous_residence_city: null,
        previous_residence_state: null,
        previous_residence_postal_code: null,
        previous_residence_county: null,
        property_owner_type_1: OwnerType.Blank,
        property_owner_name_1: "",
        property_owner_birthdate_1: null,
        property_owner_id_number_1: "",
        property_owner_percent_ownership_interest_1: null,
        property_owner_email_1: "",
        property_owner_phone_1: "",
        property_owner_name_2: null,
        property_owner_birthdate_2: null,
        property_owner_id_number_2: null,
        property_owner_percent_ownership_interest_2: null,
        property_owner_email_2: null,
        property_owner_phone_2: null,
        deceased_spouse_deathdate: null,
        deceased_spouse_name: null,
        affirm_id_property_address_match: null,
        applicant_mailing_address_different: null,
        property_date_acquired: null,
        property_date_occupied: null,
        property_address_line_1: "",
        property_unit_number: null,
        property_city: "",
        property_state: "",
        property_postal_code: "",
        property_legal_description: "",
        applicant_mail_line_1: null,
        applicant_mail_unit_number: null,
        applicant_mail_city: null,
        applicant_mail_state: null,
        applicant_mail_postal_code: null,
        applicant_identified_on_deed: null,
        court_filing_number: null,
        is_heir_property: null,
        heir_property_owners_occupy_property: null,
        property_manufactured_home: null,
        manufactured_home_make: "",
        manufactured_home_model: "",
        manufactured_home_id: "",
        property_produces_income: null,
        percent_producing_income: "",
        property_acres_occupied: null,
        waive_reason_resident_of_facility: false,
        facility_name_address: "",
        waive_reason_participator_address_confidential_program: false,
        waive_reason_active_duty: false,
        active_duty_documentation_type: "",
        waive_reason_special_drivers_license: false,
        additional_info: "",
        other_owned_properties: "",
        authorized_print_name: "",
        title_authorization: "",
        signature_of_applicant: "",
        id_details_property_owner_1: null,
        id_details_property_owner_2: null,
    }

    const initialFormDataGA: ApplicationDataGA = {
        tru_id: "",

        // section a
        other_property_homestead_exemption_address: null,
        georgia_resident_and_immigration_status: null,
        legal_alien_registration_number: null,

        // property info
        parcel_number: null,
        property_date_purchased: "",
        property_from_whom_purchased: "",
        property_purchase_price: 0,
        property_amount_lien: 0,
        property_title_type: 0,
        property_lien_due_to: null,
        property_kind_of_title_held: "",
        property_lot_size_or_acres: 0,
        property_land_lot_numbers: 0,
        property_land_district_number: 0,
        property_book: "",
        property_page: "",

        // property address
        property_address_line_1: "",
        property_unit_number: "",
        property_city: "",
        property_state: "",
        property_postal_code: "",

        // residence
        is_primary_residence: null,
        residence_address_line_1: "",
        residence_unit_number: "",
        residence_city: "",
        residence_state: "",
        residence_postal_code: "",

        // owner 1
        property_owner_name_1: "",
        property_owner_address_line_1: "",
        property_owner_unit_number_1: "",
        property_owner_city_1: "",
        property_owner_state_1: "",
        property_owner_postal_code_1: "",
        property_owner_ssn_1: "",
        property_owner_birthdate_1: "",
        property_owner_phone_1: "",
        property_owner_email_1: "",
        property_owner_id_number_1: "",
        property_owner_car_registration_county_1: "",
        property_owner_voter_registration_county_1: "",

        // owner 1 continued
        is_resident_citizen_or_authorized: null,
        legal_alien_registration: "",

        // owner 2
        property_owner_name_2: null,
        property_owner_address_line_2: null,
        property_owner_unit_number_2: null,
        property_owner_city_2: null,
        property_owner_state_2: null,
        property_owner_postal_code_2: null,
        property_owner_ssn_2: null,
        property_owner_birthdate_2: null,
        property_owner_phone_2: null,
        property_owner_email_2: null,
        property_owner_id_number_2: null,
        property_owner_voter_registration_county_2: null,
        military_service_home_of_record: null,
        receiving_homestead_exemption_elsewhere: null,
        location_of_homestead_exemption: null,
        has_spouse_or_additional_owner: null,

        // owner continued
        is_applicant_100_percent_disabled: null,
        is_applicant_100_percent_disabled_file: null,
        is_surviving_spouse_of_service_member: null,
        is_surviving_spouse_of_service_member_file: null,
        is_surviving_spouse_of_first_responder: null,
        is_surviving_spouse_of_first_responder_file: null,
        is_disabled_since_jan1_and_below_income_threshold: null,
        is_disabled_since_jan1_and_below_income_threshold_file: null,
        age_70_or_older_as_of_jan_1: null,
        age_70_or_older_as_of_jan_1_file: null,
        age_65_or_older_and_income_limit_10280: null,
        age_65_or_older_and_income_limit_10280_file: null,
        age_62_or_older_and_income_limit_25000: null,
        age_62_or_older_and_income_limit_25000_file: null,

        is_100_percent_disabled_veteran_or_surviving_spouse: null,
        is_surviving_spouse_of_kia_firefighter_or_officer: null,
        disability_and_income_below_25000_as_of_jan_1: null,
        disability_and_income_below_25000_as_of_jan_1_file: null,
        disability_and_income_below_25000_as_of_jan_1_file_2: null,

        // property continued
        is_property_used_for_business: null,
        property_business_use_description: null,
        is_property_rented: null,
        property_part_rented: "",

        // signature & date
        signature_of_applicant: "",
        signature_date: "",

        sectionC1_income_tax_year: null,
        sectionC1: [
            {
                description: 'Line 1: Total Income from Public or Private retirement, disability or pension system',
                applicant1: 0,
                applicant2: 0
            },
            {
                description: 'Line 2: Total Income from Social Security',
                applicant1: 0,
                applicant2: 0
            },
            {
                description: 'Line 3: Total Income from both retirement and Social Security (Line 1 plus Line 2)',
                applicant1: 0,
                applicant2: 0
            },
            {
                description: 'Line 4: Maximum Social Security amount (from Tax Receiver)',
                applicant1: 0,
                applicant2: 0
            },
            {
                description: 'Line 5: Retirement Income over maximum Social Security (Line 3 less Line 4) - If less than 0, use 0',
                applicant1: 0,
                applicant2: 0
            },
            {
                description: 'Line 6: Other income from all sources',
                applicant1: 0,
                applicant2: 0
            },
            {
                description: 'Line 7: Adjusted Income (Line 5 plus Line 6)',
                applicant1: 0,
                applicant2: 0
            },
            {
                description: 'Line 8: Standard or Itemized Deductions from Georgia Income Tax Return',
                applicant1: 0,
                applicant2: 0
            },
            {
                description: 'Line 9: Personal Exemption amount from Georgia Income Tax Return',
                applicant1: 0,
                applicant2: 0
            },
            {
                description: 'Line 10: Net Income (Line 7 less Lines 8 and 9)',
                applicant1: 0,
                applicant2: 0
            }
        ],

        sectionC2_income_tax_year: null,
        sectionC2: [
            {
                description: "Line 1",
                name: "",
                value: 0
            },
            {
                description: "Line 2",
                name: "",
                value: 0
            },
            {
                description: "Line 3",
                name: "",
                value: 0
            },
            {
                description: "Line 4",
                name: "",
                value: 0
            },
            {
                description: "Line 5",
                name: "",
                value: 0
            },
            {
                description: "Line 6",
                name: "",
                value: 0
            },
            {
                description: "Line 7",
                name: "",
                value: 0
            }
        ],
        sectionC2_gross_income_total: null,

        sworn_date: null
    }

    const TX_STATE_STORAGE_KEY = "myApplicationTx50114"
    const GA_STATE_STORAGE_KEY = "myApplicationGA1008"
    const storageKeys: StorageKeys = {
        ga: GA_STATE_STORAGE_KEY,
        tx: TX_STATE_STORAGE_KEY
    }
    const dateFields = [
        "property_owner_birthdate_1",
        "property_owner_birthdate_2",
        "deceased_spouse_deathdate",
        "property_date_acquired",
        "property_date_occupied"
    ]
    const uploadFileIds = ref<FormUploadFilesIDs[]>([])

    try {
        const key = storageKeys[currentState]
        const jsonString = sessionStorage.getItem(key)
        if (jsonString) {
            // console.log("Restoring form state....")
            const parsedData = JSON.parse(jsonString, (key, value) => {
                if (dateFields.includes(key) && typeof value === "string") {
                    return new Date(value);
                }
                return value;
            });
            if (currentState === "ga") {
                // nosemgrep - working with local data only
                Object.assign(initialFormDataGA, parsedData)
            }
            else if (currentState === "tx") {
                // nosemgrep - working with local data only
                Object.assign(initialFormData, parsedData)
            }
        } else {
            // console.log("Initializing state with defaults...")
        }
    } catch (ex) {
        console.warn("Failed to restore state from storage", ex)
    }
    const fields = ref<ApplicationForm>(initialFormData)
    const gaFields = ref<ApplicationDataGA>(initialFormDataGA)

    const formLoading = ref(false)
    const uploadState = ref<number>(0)
    const publicApi = useAPI({ authGuard: false })


    const getOrSetFormLoading = (value?: boolean) => {
        if (value !== undefined) formLoading.value = value
        return formLoading.value;
    }

    const showBirthDateWarning = (): boolean => {
        // Rules: age >= 65 AND 'person age 65 or older' is unchecked
        // then show warning
        const age = today.diff(fields.value.property_owner_birthdate_1, "year")
        return (!fields.value.exemption_age_65_plus && (age >= 65))
    }

    const extractText = async (applicationID: string, uploadID: string, api_key: string) => {
        const payload = {
            "tru_id": applicationID,
            "upload_id": uploadID
        }
        const options = {
            headers: {
                "Content-Type": "application/json",
                "X-Api-Key": api_key,
            }
        }
        try {
            const response = await axios.post("applications/extract_text", payload, options)
            return response ? response.data as StateIdExtraction : null
        } catch (ex) {
            return null;
        }
    }

    const uploadFile = async (instanceKey: string, file: File, customerID: string, itemID: string = "application_attachments", feedbackElementID: string = "attachments", input: HTMLInputElement | null = null, api_key: string | null = null): Promise<Upload | null> => {

        // Delete any previous uploaded file
        if (uploadFileIds.value.length) {
            const id = feedbackElementID
            const upload = uploadFileIds.value.filter((upload: FormUploadFilesIDs) => upload.id === id)[0]
            if (upload) {
                deletePrevUploadedFile(upload.uploadID)
                uploadFileIds.value = uploadFileIds.value.filter(
                    (upload: FormUploadFilesIDs) => upload.id !== id
                )
                console.log("Successfully deleted the previously uploaded file")
            }
        }

        if (input && input.value.length <= 0) {
            uploadFileMessage(input, feedbackElementID, { message: "No file was selected.", status: 2 })
            return null
        }
        if (input) {
            uploadFileMessage(input, feedbackElementID, { message: "Loading", status: 0 })
        }
        const applicationID = instanceKey
        // 1 create upload - get put url
        // 2 put content
        // 3 mark upload as completed
        const resp = await publicApi.post('/uploads/', {
            tru_id: applicationID,
            customer_id: customerID,
            item_id: itemID,
            file_name: file.name,
            file_size: file.size,
            content_type: file.type,
            meta: {
                application_id: applicationID,
                ...feedbackElementID ? { document_type: feedbackElementID } : {}
            }
        }
        )
        const upload: Upload = resp.data
        const uploadCfg = upload.upload_url!

        uploadState.value += 1
        const uplRes = await axios.request({
            ...uploadCfg,
            data: file,
            validateStatus: () => true
        })

        if (uplRes.status !== 200) {
            const message = getApiErrorMessage({ response: uplRes } as any)
            toast.error(message)
            if (input) {
                uploadFileMessage(input, feedbackElementID, { message: message, status: 2 })
            }
            await deletePrevUploadedFile(upload.id)
        } else {
            await publicApi.post(`/uploads/${upload.id}/complete`);

            const regex = /^property-owner-id-file-([1-2])$/;
            const match = feedbackElementID.match(regex);

            if (match && api_key) {
                const ownerIndex = match[1];
                const MAX_SIZE_BYTES = 10 * 1024 * 1024;

                if (file.size < MAX_SIZE_BYTES) {
                    const extractionResult = await extractText(applicationID, upload.id, api_key);
                    if (extractionResult) {
                        if (ownerIndex === '1') {
                            fields.value.id_details_property_owner_1 = extractionResult;
                        } else if (ownerIndex === '2') {
                            fields.value.id_details_property_owner_2 = extractionResult;
                        }
                        upload.meta.extraction = extractionResult;
                    }
                } else {
                    console.warn(`File size of ${file.size} bytes exceeds the maximum permitted size for text extraction of ${MAX_SIZE_BYTES} bytes`)
                }
            }
            if (input) {
                uploadFileMessage(input, feedbackElementID, { message: `Successfully uploaded file with the filename: '${upload.file_name}'`, status: 1 })
            }
        }
        uploadState.value -= 1

        // Save upload history
        uploadFileIds.value?.push({
            id: feedbackElementID,
            uploadID: upload?.id as string
        })

        return upload
    }

    const deletePrevUploadedFile = async (uploadID: string) => {
        try {
            await publicApi.delete(`/uploads/${uploadID}`)
        } 
        catch (error: any) {
            console.warn("Unable to delete the previously uploaded file")
            toast.error(getApiErrorMessage(error))
        }
    }

    const uploadFileMessage = (input: HTMLInputElement, elementID: string, response: { message: string; status: number }) => {
        const fileUploadStatusID = elementID + "-upload-status"
        const fileUploadStatusSuccess = document.getElementById(fileUploadStatusID + "-1") as HTMLDivElement
        const fileUploadStatusSuccessMessage = document.getElementById(fileUploadStatusID + "-1-message") as HTMLDivElement
        const fileUploadStatusFailed = document.getElementById(fileUploadStatusID + "-2") as HTMLDivElement
        const fileUploadStatusFailedMessage = document.getElementById(fileUploadStatusID + "-0-message") as HTMLDivElement
        const fileUploadStatusLoading = document.getElementById(fileUploadStatusID + "-0") as HTMLDivElement

        if (response.status === 1) { // Success
            if (fileUploadStatusSuccess) {
                fileUploadStatusSuccess.style.display = "inline-block"
                fileUploadStatusFailed.style.display = "none"
                fileUploadStatusLoading.style.display = "none"
                fileUploadStatusSuccessMessage.textContent = response.message
            }
        }
        else if (response.status === 2) { // Failed
            if (fileUploadStatusFailed) {
                fileUploadStatusSuccess.style.display = "none"
                fileUploadStatusFailed.style.display = "inline-block"
                fileUploadStatusLoading.style.display = "none"
                fileUploadStatusFailedMessage.textContent = response.message

                // If there is an error, remove the
                // file in the input field
                input.value = ""
            }
        }
        else if (response.status === 0) { // Loading
            if (fileUploadStatusFailed) {
                fileUploadStatusSuccess.style.display = "none"
                fileUploadStatusFailed.style.display = "none"
                fileUploadStatusLoading.style.display = "inline-block"
            }
        }
    }

    const getUploadedFiles = computed(() => uploadFileIds.value)

    return {
        extractText,
        formLoading,
        getUploadedFiles,
        currentState,
        GA_STATE_STORAGE_KEY,
        TX_STATE_STORAGE_KEY,
        initialFormData,
        initialFormDataGA,
        fields,
        gaFields,
        getOrSetFormLoading,
        showBirthDateWarning,
        uploadFile,
        uploadFileMessage,
        uploadState,
        deletePrevUploadedFile
    }
})