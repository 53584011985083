import { ref, computed } from "vue"
import { defineStore } from "pinia";
import { useAPI } from "@/helpers/services/api"
import type { Customer } from "@/helpers/interface/admin-page"
import type { ImpersonationCache } from "@/helpers/interface/general";
import { StorageKeyEnum, storageManager } from "@/helpers/storageManager";
import { validateUserPermission } from "@/helpers/common";

export const useProfile = defineStore("profile", () => {

    const api = useAPI()
    const customerAccess = ref<Customer>()
    const userPermissions = ref<string[]>([])
    const loadingState = ref(false)

    const fetchCustomerAccess = async () => {
        loadingState.value = true
        return await api.get("/customers/current")
            .then(response => {
                customerAccess.value = response.data as Customer
            })
            .catch(() => {
                customerAccess.value = { customer_id: "", name: "-" }
            })
            .finally(() => loadingState.value = false)
    }


    const fetchUserPermissions = async () => {
        loadingState.value = true
        return await api.get("/users/auth0/permissions")
            .then(response => {
                userPermissions.value = response.data
            })
            .finally(() => loadingState.value = false)
    }


    const assumeRole = async (roleID: string, roleName: string) => {
        const data: ImpersonationCache = { roleID, roleName }
        storageManager.setItem(StorageKeyEnum.ImpersonationCache, JSON.stringify(data))
    }


    const getCustomerAccess = computed(() => customerAccess.value)


    const getCurrentPermissions = computed(() => userPermissions.value)


    const enableBetaFeatures = computed(() => {
        loadingState.value = true

        const customerBetaFeatureEnabled = customerAccess.value?.enable_beta_features
        storageManager.setItem(StorageKeyEnum.EnableBetaFeaturesCL, customerBetaFeatureEnabled ? "on" : "off")

        const enabled = customerBetaFeatureEnabled || storageManager.getItem(StorageKeyEnum.EnableBetaFeatures) === "on"

        loadingState.value = false
        return enabled
    })

    // To determine if its a TrueRollers
    const isTRs = computed((): boolean => validateUserPermission("read", "customers"))

    // Determine if investigation services are enabled for customers
    const investigationServicesEnabled = computed((): boolean => customerAccess.value?.investigation_services || false)

    // Determine if the user can view the Investigate Now list
    const canViewInvestigateNowList = computed((): boolean => {
        // TrueRollers can always view
        if (isTRs.value) return true

        return investigationServicesEnabled.value
    })

    // Determine if the user can view the Unreleased IR List
    const canViewUnreleasedIRList = computed((): boolean => {
        // TrueRollers can always view
        if (isTRs.value) return true

        if (!investigationServicesEnabled.value) return false

        return validateUserPermission('read', 'unreleased_investigation_reports')
    })

    // Determine if the user can view the Released IR List
    const canViewReleasedIRList = computed((): boolean => {
        // TrueRollers can always view
        if (isTRs.value) return true

        if (!investigationServicesEnabled.value) return false

        return validateUserPermission('read', 'released_investigation_reports')
    })

    const hasPromonAccess = computed(() => getCustomerAccess.value?.proactive_monitoring || isTRs.value)
    const hasApplicationVetterAccess = computed(() => getCustomerAccess.value?.application_vetter || isTRs.value)

    const landingPage = computed((): {} => {
        const promon = { "name": "candidateList", "params": { "queue": "inbox" } }
        if (isTRs.value) return promon

        if (getCustomerAccess.value?.application_vetter && !getCustomerAccess.value?.proactive_monitoring) {
            return { name: "applicationVetter" }
        }
        else if (!getCustomerAccess.value?.application_vetter && getCustomerAccess.value?.proactive_monitoring) {
            return promon
        }
        else {
            return { name: "admin" }
        }
    })

    return {
        assumeRole,
        customerAccess,
        userPermissions,
        loadingState,
        fetchCustomerAccess,
        fetchUserPermissions,
        getCustomerAccess,
        getCurrentPermissions,
        enableBetaFeatures,
        canViewInvestigateNowList,
        canViewUnreleasedIRList,
        canViewReleasedIRList,
        investigationServicesEnabled,
        isTRs,
        hasPromonAccess,
        hasApplicationVetterAccess,
        landingPage
    }
})
