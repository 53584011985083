<template>
    <RouterView />
</template>

<script setup lang="ts">
import { watch } from "vue"
import { RouterView } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue"
import { PFHFConfig } from "@/helpers/pfhf"
import {
    getCookie,
    setCookie,
} from "@/helpers/common"
import * as Sentry from "@sentry/vue";
import { usePendoStore } from "@/stores/pendo";

const { user, isAuthenticated } = useAuth0()
const pendoStore = usePendoStore();

watch(() => user.value, async (userValue) => {

    if (isAuthenticated.value) {

        const email = userValue.email || ''
        if (!getCookie('productFruitsOfLoom')) {
            setCookie('productFruitsOfLoom', email)
        }

        // Load Product fruit and Happy fox
        PFHFConfig()

        pendoStore.init(user.value)
    }

    Sentry.setUser({
        id: userValue?.sub,
        email: userValue?.email,
        username: userValue?.name,
        ip_address: userValue?.ip,
    });
})
</script>