import { createRouter, createWebHistory } from 'vue-router'
import CandidateList from '@/views/CandidateList.vue'
import CandidateDetails from '@/views/CandidateDetails.vue'
import CandidateDocketReport from '@/views/CandidateDocketReport.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import Admin from '@/views/Admin.vue'
import TaxRollDetails from '@/views/TaxRollExplorerDetails.vue'
import Logout from '@/views/Logout.vue'
import Login from '@/views/Login.vue'
import DataDrop from '@/views/trueload/DataDrop.vue'
import DataConfig from "@/views/trueload/DataConfig.vue";
import InvestigationReport from "@/views/InvestigationReport.vue";
import Callback from '@/views/Callback.vue'
import { authGuard } from '@auth0/auth0-vue';
import DataRequest from "@/views/trueload/DataRequest.vue";
import ApplicationVetter from "@/views/ApplicationVetter.vue";
import ApplicationFormResolver from "@/views/ApplicationFormResolver.vue";
import ApplicationDetails from "@/views/ApplicationDetails.vue"
import DataIngest from "@/views/trueload/DataIngest.vue";
import DownloadExplorer from "@/views/DownloadExplorer.vue"
import OnboardingToolbox from "@/views/OnboardingToolbox.vue"

import { APPVET_DETAILS_VIEW_ONLY_PATH, setPageTitle } from '@/helpers/common'
import BackgroundJob from '@/views/trueload/BackgroundJob.vue'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        { path: '/', name: 'home', alias: '/login', component: Login },
        { path: '/callback', name: 'callback', component: Callback },

        // Candidates List
        { path: '/candidates-list/:queue', name: 'candidateList', component: CandidateList, beforeEnter: authGuard },
        { path: '/candidate-details/:tru_id', name: 'candidateDetails', component: CandidateDetails, beforeEnter: authGuard },
        {
            // /candidate-details/?tru_id=:tru_id -> /candidate-details/:tru_id
            path: '/candidate-details/',
            redirect: to => {
                return { path: '/candidate-details/' + to.query.tru_id, query: {} }
            }
        },
        { path: '/admin-candidate-docket/:tru_id', name: 'candidateDocketReport', component: CandidateDocketReport, beforeEnter: authGuard },

        { path: '/taxroll-details/:tru_id', name: 'taxrollDetails', component: TaxRollDetails, meta: { title: "Tax Roll Parcel Details" }, beforeEnter: authGuard },

        // Application Details - View only
        { path: `${APPVET_DETAILS_VIEW_ONLY_PATH}:tru_id`, name: 'applicationDetailsViewOnly', component: ApplicationDetails, meta: { title: "Application Details" }, beforeEnter: authGuard },
        {
            // redirect old /trueapp/?tru_id=? URLs to /application-view/:tru_id
            path: '/trueapp/',
            name: 'trueApp',
            redirect: to => {
                return { path: APPVET_DETAILS_VIEW_ONLY_PATH + to.query.tru_id, query: {} }
            }
        },
        {
            // redirect /application-view/?tru_id=? URLs to /application-view/:tru_id
            path: APPVET_DETAILS_VIEW_ONLY_PATH,
            redirect: to => {
                return { path: APPVET_DETAILS_VIEW_ONLY_PATH + to.query.tru_id, query: {} }
            }
        },
        // Application Details - with editing
        { path: '/application-details/:tru_id', name: 'applicationDetails', component: ApplicationDetails, meta: { title: "Application Details" }, beforeEnter: authGuard },
        { path: '/investigation-report/:tru_id/:ir_id?', name: 'investigationReport', component: InvestigationReport, meta: { title: "Investigation Report" }, beforeEnter: authGuard },
        { path: '/downloads', name: "downloads", component: DownloadExplorer, meta: { title: "Downloads" }, beforeEnter: authGuard },

        { path: '/admin/:page?', name: 'admin', component: Admin, meta: { title: "Admin" }, beforeEnter: authGuard },
        { path: '/logout', name: 'logout', component: Logout, beforeEnter: authGuard },
        { path: '/data-drop/:data_drop_id', name: 'dataDrop', component: DataDrop },
        { path: '/data-requests/:data_request_id', name: 'dataRequest', component: DataRequest, beforeEnter: authGuard },
        { path: '/onboarding/', name: 'onboarding-toolbox', component: OnboardingToolbox, meta: { title: "Onboarding Toolbox" }, beforeEnter: authGuard },
        { path: '/onboarding/all-configs', name: 'all-configs', component: OnboardingToolbox, meta: { title: "All Configs" }, beforeEnter: authGuard },
        { path: '/onboarding/client-configs', name: 'client-configs', component: OnboardingToolbox, meta: { title: "Client Configs" }, beforeEnter: authGuard },
        { path: '/onboarding/ingestions', name: 'ingestions', component: OnboardingToolbox, meta: { title: "Ingestions" }, beforeEnter: authGuard },
        { path: '/onboarding/data-requests', name: 'data-requests', component: OnboardingToolbox, meta: { title: "Data Requests" }, beforeEnter: authGuard },
        { path: '/onboarding/scorecards', name: 'scorecards', component: OnboardingToolbox, meta: { title: "Client Reports" }, beforeEnter: authGuard },
        { path: '/onboarding/background-jobs', name: 'background-jobs', component: OnboardingToolbox, meta: { title: "Onboarding Tools" }, beforeEnter: authGuard },
        { path: '/onboarding/ingestions/:id', name: 'ingestion', component: DataIngest, beforeEnter: authGuard },
        { path: '/onboarding/scorecard/:job_id', name: 'scorecard', component: BackgroundJob, beforeEnter: authGuard },
        { path: '/onboarding/cleanup/:job_id', name: 'cleanup', component: BackgroundJob, beforeEnter: authGuard },
        { path: '/onboarding/observation/:job_id', name: 'generate_observations', component: BackgroundJob, beforeEnter: authGuard },
        { path: '/onboarding/extracts/:job_id', name: 'generate_extracts', component: BackgroundJob, beforeEnter: authGuard },
        // data-load-configs & data-load-configs/:id
        { path: '/onboarding/configs/:id', name: 'onboardingConfig', component: DataConfig, beforeEnter: authGuard },
        { path: '/application-vetter', name: 'applicationVetter', component: ApplicationVetter, meta: { title: "Application Vetter" }, beforeEnter: authGuard },

        // public forms
        {
            path: '/apply/:form_id',
            name: 'application-tx-50-114',
            redirect: (to) => {
                return `/apply/tx/${to.params.form_id}`;
            },
        },
        {
            path: '/apply/:state/:form_id',
            name: 'application-form-resolver',
            component: ApplicationFormResolver,
            props: (route) => ({
                state: route.params.state,
                formId: route.params.form_id,
            }),
        },

        // Page 404
        { path: '/:pathMatch(.*)*', name: 'page404', component: PageNotFound },
    ],
    linkExactActiveClass: "active"
})


router?.beforeEach((to, from, next) => {
    if (to.meta?.title) {
        setPageTitle(to.meta.title as string)
    }
    next();
})

export default router
