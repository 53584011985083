<template>
  <TheHeader/>
  <main class="p-2">
    <SidebarNavigation :menus="sidebarMenus" :collapsed="isCollapsed" :activeMenu="activeMenu"
        :key="activeMenu" @menuSelected="handleMenuSelected" @collapseChanged="handleCollapsed" />
    <div :class="['sidebar-content-wrapper', { 'sidebar-collapsed': isCollapsed }]">
      <div class="sidebar-content">
        <p v-if="profile.loadingState" class="text-center">Loading...</p>
        <p v-else-if="!profile.isTRs" class="text-center">Your user level does not have permission to access this page</p>
        <Card v-else-if="activeMenu === 'all-configs'">
          <template #content>
            <configList/>
          </template>
        </Card>
        <Card v-else-if="activeMenu === 'client-configs'">
          <template #content>
            <customerConfigs/>
          </template>
        </Card>
        <Card v-else-if="activeMenu === 'ingestions'">
          <template #content>
            <ingestions/>
          </template>
        </Card>
        <Card v-else-if="activeMenu === 'data-requests'">
          <template #content>
            <dataRequests />
          </template>
        </Card>
        <Card v-else-if="activeMenu === 'scorecards'">
          <template #content>
            <scorecards />
          </template>
        </Card>
        <Card v-else-if="activeMenu === 'background-jobs'">
          <template #content>
            <backgroundJobs />
          </template>
        </Card>
        <Card v-else>
          <template #content>
            <configList />
          </template>
        </Card>
      </div>
    </div>
  </main>
</template>
<script setup lang="ts">
import {computed, ref} from 'vue';
import {setPageTitle} from "@/helpers/common";
import { useRouter } from "vue-router";


import backgroundJobs from '@/components/Admin/Onboarding/Onboarding-backgroundJobs.vue';
import configList from '@/components/Admin/Onboarding/Onboarding-configList.vue';
import customerConfigs from '@/components/Admin/Onboarding/Onboarding-customerConfigs.vue'
import ingestions from '@/components/Admin/Onboarding/Onboarding-ingestionList.vue';
import dataRequests from '@/components/Admin/Onboarding/Onboarding-dataRequestList.vue';
import scorecards from '@/components/Admin/Onboarding/Onboarding-scorecardViewer.vue'
import TheHeader from "@/components/TheHeader.vue";
import type {SidebarNavigationMenu} from "@/helpers/interface/candidates";
import SidebarNavigation from "@/components/Shared/SidebarNavigation.vue";
import Card from "primevue/card";
import {useProfile} from "@/stores/profile";

const isCollapsed = ref(false)
const router = useRouter()
const profile = useProfile()

const handleCollapsed = (collapsed: boolean) => isCollapsed.value = collapsed

const activeMenu = computed(() => router.currentRoute.value.name as string);
const sidebarMenus = ref<SidebarNavigationMenu[]>([
  { label: "All Configs", iconClass: "fa-solid fa-scroll", id: "all-configs" },
  { label: "Client Configs", iconClass: "fa-regular fa-address-book", id: "client-configs" },
  { label: "Ingestions", iconClass: "fa-solid fa-rotate-right", id: "ingestions" },
  { label: "Data Requests", iconClass: "fa-solid fa-file-arrow-down", id: "data-requests" },
  { label: "Client Reports", iconClass: "fa-solid fa-file-circle-check", id: "scorecards" },
  { label: "Onboarding Tools", iconClass: "fa-solid fa-screwdriver-wrench", id: "background-jobs" },
])
const handleMenuSelected = (menuId: string) => {
  setPageTitle(sidebarMenus.value.find((menu) => menu.id === menuId)?.label || 'Onboarding Tools')
  router.push({path: `/onboarding/${menuId}`, name: menuId})
}
</script>