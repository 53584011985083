<template>
<div class="row">
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({ name: 'ingestion', params: { id: 'new' }}).href)">
        New Ingestion
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({name: 'onboardingConfig', params: { id: 'new' }}).href)">
        New Config
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({ name: 'dataRequest', params: { data_request_id: 'new' }}).href)">
        New Data Request
    </button>
  </div>
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
             @click="SendToWindow($router.resolve({ name: 'generate_extracts', params: { job_id: 'new' }}).href)">
        Generate Extracts
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({ name: 'generate_observations', params: { job_id: 'new' }}).href)">
        Generate Observations
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
             @click="SendToWindow('https://drive.google.com/drive/folders/1-z8UgEvKhK6wnbOqrm03jJCnNvqOCNIv')">
        Customer Documentation
    </button>
  </div>
  <div class="d-flex">
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
            @click="SendToWindow($router.resolve({ name: 'scorecard', params: { job_id: 'new' }}).href)">
        New Scorecard
    </button>
    <button class="searchButton cbFormTextField-sm mt-2" type="button"
             @click="SendToWindow($router.resolve({ name: 'cleanup', params: { job_id: 'new' }}).href)">
        Finalize Refresh
    </button>
  </div>
</div>
</template>

<script setup lang="ts">

const SendToWindow = (url: string) => {
  window.open(url, '_blank')
}

</script>

<style scoped>

</style>