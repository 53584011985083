<template>
    <header>
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid">
                <div>
                    <a href="/">
                        <img src="/images/trueroll_app_logo6.png" alt="site-header-logo" class="header-logo" />
                    </a>
                    <a href="javascript:;" id="customerInfo">
                        <small class="mx-3" v-if="showCustomerInfoButton && currentCustomer" id="currentCustomerAccess"
                            @click="() => showCustomerInfo = true">
                            <span :title="'ID: ' + currentCustomer.customer_id">
                                {{ currentCustomer.name }}
                            </span>
                        </small>
                    </a>
                    <RoleImpersonateChip />
                </div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarTogglerDemo02" style="background-color: none">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                    <div class="">
                        <a href="javascript:;" class="pf-notifications" id="pfNotifications" style="display: none">
                            <i class="fa fa-bell fa-lg"></i>
                            <span class="pf-notifications-badge" id="pfNotificationsBadge">0</span>
                        </a>
                        <router-link v-if="hasPromonAccess" :class="candidateListActiveClass + ' navbar-link'"
                            :to="{ name: 'candidateList', params: { queue: 'inbox' } }">Proactive
                            Monitoring</router-link>
                        <router-link v-if="hasApplicationVetterAccess" id="applicationVetter" :to="{ name: 'applicationVetter' }"
                            :class="'navbar-link'">Application Vetter</router-link>
                        <a :class="{ 'active': getHelpIsVisible, 'navbar-link': true }" id="getHelp" href="javascript:;"
                            @click="toggleGetHelpDialog">Help</a>
                        <a  :class="{ 'active': menuAdminActive, 'navbar-link': true }" href="javascript:;"
                            @click="toggleAdminMenu()">Admin</a>
                        <a v-if="hasReadConfigsPermission && hasReadDataRequestsPermission" :class="{ 'active': menuOnboardingActive, 'navbar-link': true }" href="javascript:;"
                            @click="toggleOnboardingMenu()">Onboarding</a>
                        <router-link :to="{ name: 'logout' }" :class="'navbar-link'">Logout</router-link>
                    </div>
                </div>
            </div>
        </nav>
    </header>

    <teleport to="body">
        <ModalDialog v-if="showCustomerInfo && currentCustomer" :title="'Customer Information'"
            :close="() => showCustomerInfo = false">
            <table class="table table-striped table-bordered">
                <tbody>
                    <tr>
                        <td>Customer Name</td>
                        <td>
                            <Skeleton height="2.2rem" width="200px" v-if="getCustomerListLoading"></Skeleton>
                            <Select v-if="!getCustomerListLoading" editable v-model="customerID" :options="customerList"
                                optionLabel="name" optionValue="customer_id" class="w-100"
                                :disabled="changeCustomerAccessLoading" @change="handleCustomerChange"
                                @input="handleCustomerInput" @focus="handleCustomerFocus" />
                        </td>
                    </tr>
                    <tr>
                        <td>Customer ID</td>
                        <td>{{ currentCustomer.customer_id }}</td>
                    </tr>
                    <tr>
                        <td>Application Vetter</td>
                        <td>{{ currentCustomer.application_vetter ? "Enabled" : "Disabled" }}</td>
                    </tr>
                    <tr>
                        <td>Investigation Services</td>
                        <td>{{ currentCustomer.investigation_services ? "Enabled" : "Disabled" }}</td>
                    </tr>
                    <tr>
                        <td>TaxRoll Received</td>
                        <td>
                            <span v-tooltip="formatDate(currentCustomer.taxroll_refresh_date)">
                                {{ dayjs(currentCustomer.taxroll_refresh_date).fromNow() }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <template #footer>
                <slot name="footer-button"></slot>
                <Button @click="() => showCustomerInfo = false" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>
    </teleport>
</template>

<style>
.navbar a#customerInfo {
    color: var(--general-text-color);
}
</style>

<script setup lang="ts">
import { validateUserPermission, promonQueueTabs, formatDate, isImpersonatingRole, qShadowRoot, hidePFGetHelpButton, getApiErrorMessage } from "@/helpers/common"
import { useProfile } from "@/stores/profile"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { useRoute } from "vue-router"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import dayjs from "dayjs"
import Button from "primevue/button"
import relativeTime from 'dayjs/plugin/relativeTime'
import RoleImpersonateChip from "@/components/General/RoleImpersonateChip.vue"
import router from "@/router"
import type { Customer, CustomerList } from "@/helpers/interface/admin-page"
import { useAdminDetails } from "@/stores/adminDetails"
import type { AxiosError } from "axios"
import { toast } from "@/helpers/toast"
import Skeleton from "primevue/skeleton"
import Select from "primevue/select"

dayjs.extend(relativeTime);

const route = useRoute()
const storeAdminDetails = useAdminDetails()
const storeProfile = useProfile()
const currentQueue: any = route?.params?.queue || ""
const queues = promonQueueTabs.map(queue => queue.id)
const candidateListActiveClass = queues.includes(currentQueue) ? 'active' : ''
const currentCustomer = computed(() => storeProfile.getCustomerAccess)
const hasReadCustomersPermission = computed(() => validateUserPermission("read", "customers"))
const hasReadConfigsPermission = computed(() => validateUserPermission("read", "ingestion_configs"))
const hasReadDataRequestsPermission = computed(() => validateUserPermission("read", "data_requests"))
const getCustomerListLoading = ref(false)
const availableCustomersList = computed((): CustomerList => (storeAdminDetails.getCustomerList))
const changeCustomerAccessLoading = ref(false)

const hasPromonAccess = computed(() => storeProfile.hasPromonAccess)
const hasApplicationVetterAccess = computed(() => storeProfile.hasApplicationVetterAccess)

const currentCustomerAccess = computed(() => storeProfile.getCustomerAccess as Customer)
const customerID = ref<string>("")
const currentItemOriginalValue = ref<string>("")
const customerSearchValue = ref("")
const customerList = computed((): Customer[] => {
    return availableCustomersList.value.data.filter(c => {
        const search = customerSearchValue.value.toLowerCase()
        return !!c.customer_id && (!search || (search && c.name.toLowerCase().includes(search)))
    })
})

const showCustomerInfoButton = computed(() => {
    return hasReadCustomersPermission.value || isImpersonatingRole()
})
const defaultAdminPage = ref<string>("")
const hasPermissionToAccessUsersList = computed(() => validateUserPermission("create", "users") && validateUserPermission("update", "users"))

const getHelpIsVisible = ref(false)
const showCustomerInfo = ref(false)

watch(() => hasPermissionToAccessUsersList.value, (hasAccess: boolean) => {
    defaultAdminPage.value = hasAccess ? "users" : "mail-module"
})

watch(() => currentCustomerAccess.value, (access: Customer) => {
    customerID.value = access?.customer_id as string
    currentItemOriginalValue.value = access?.customer_id as string
    if (hasReadCustomersPermission.value) {
        fetchCustomersList()
    }
})

const toggleAdminMenu = () => {
    router?.push({ name: "admin", params: { page: defaultAdminPage.value } })
    return
}
const toggleOnboardingMenu = () => {
    router?.push({ name: "onboarding-toolbox" })
    return
}
const menuAdminActive = computed(() => !!route?.path?.includes("/admin/"))
const menuOnboardingActive = computed(() => !!route?.path?.includes("/onboarding/"))

const getHelpDialog = (visible: boolean = false) => {
    const container = document?.querySelector(".productfruits--container") as HTMLDivElement
    if (!container) return

    const liferingModal = qShadowRoot(".productfruits--lifering-modal")
    const helpText = qShadowRoot(".productfruits--lifering-text")
    const helpButton = document?.querySelector("#getHelp") as HTMLDivElement

    if (!liferingModal || !helpText || !helpButton) return

    const helpButtonRect = helpButton.getBoundingClientRect()
    const navbarBottom = document?.querySelector("header")?.getBoundingClientRect().bottom
    if (!navbarBottom) return

    getHelpIsVisible.value = visible
    helpText.click()

    // 'Get Help` styling
    const dialogWidth = liferingModal.offsetWidth
    const dialogLeft = helpButtonRect.left + (helpButtonRect.width / 2) - (dialogWidth / 2)
    liferingModal.style.marginTop = `${navbarBottom}px`
    liferingModal.style.marginLeft = `${dialogLeft}px`
    liferingModal.style.maxHeight = "600px"

    // Feedback styling and behavior
    const feedbackButton = qShadowRoot(".productfruits--lifering-modal-feedback")
    if (feedbackButton && !feedbackButton.dataset.listenerAdded) {
        feedbackButton.addEventListener("click", toggleFeedbackPopup)
        feedbackButton.dataset.listenerAdded = "true"
    }

    // Livechat styling and behavior
    const livechatButton = qShadowRoot(".productfruits--lifering-modal-chat")
    if (livechatButton && !livechatButton.dataset.listenerAdded) {
        livechatButton.addEventListener("click", toggleGetHelpDialog)
        livechatButton.dataset.listenerAdded = "true"
    }
}

// Outside click
const handleClickOutside = (event: MouseEvent) => {
    if (!getHelpIsVisible?.value) return
    const liferingModal = qShadowRoot(".productfruits--lifering-modal")
    const helpButton = document?.querySelector("#getHelp") as HTMLDivElement
    const container = document?.querySelector(".productfruits--container") as HTMLDivElement
    const helpText = qShadowRoot(".productfruits--lifering-text")

    if (!liferingModal || !container || !helpButton || !helpText) return

    if (!helpButton?.contains(event.target as Node)) {
        if (!container.contains(event.target as Node) && helpText) {
            getHelpDialog(false)
        }
    }
}

const toggleFeedbackPopup = () => {
    const feedbackPopup = qShadowRoot(".productfruits--feedback-popup")
    const helpButton = document?.querySelector("#getHelp") as HTMLDivElement
    const helpButtonRect = helpButton.getBoundingClientRect()
    const navbarBottom = document?.querySelector("header")?.getBoundingClientRect().bottom || 0
    const dialogWidth = feedbackPopup.offsetWidth
    const dialogLeft = helpButtonRect.left + (helpButtonRect.width / 2) - (dialogWidth / 2)

    if (feedbackPopup) {
        feedbackPopup.style.left = `${dialogLeft}px`
        feedbackPopup.style.top = `${navbarBottom + 25}px`
    }
}

const toggleGetHelpDialog = () => {
    getHelpIsVisible.value = !getHelpIsVisible.value
    getHelpDialog(getHelpIsVisible.value)
}


const handleCustomerInput = (e: Event) => {
    const input = e.target as HTMLInputElement
    customerSearchValue.value = input.value
}

const handleCustomerFocus = (e: Event) => {
    const input = e.target as HTMLInputElement
    input.select()
}

const handleCustomerChange = async () => {
    if (customerID.value === currentItemOriginalValue.value) return
    if (!customerList.value.filter(d => d.id === customerID.value).length) return

    await changeCustomerAccess()
}

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error, { "featureName": "Customer List" })
    toast.error(message)
}

const fetchCustomersList = async () => {
    getCustomerListLoading.value = true
    await storeAdminDetails.fetchCustomersList(loadFailureHandler)
    getCustomerListLoading.value = false
}

let changeCustomerAccess = async () => {
    changeCustomerAccessLoading.value = true
    await storeAdminDetails.changeCustomerAccess(customerID.value, toast, loadFailureHandler)
    changeCustomerAccessLoading.value = false
}

onMounted(() => {
    (window as any)?.$productFruits?.push(['pageChanged'])
    defaultAdminPage.value = hasPermissionToAccessUsersList.value ? "users" : "mail-module"
    document?.addEventListener("click", handleClickOutside, true);
    hidePFGetHelpButton()
})

onUnmounted(() => {
    const feedbackButton = qShadowRoot(".productfruits--lifering-tool-button")
    if (feedbackButton && feedbackButton.dataset.listenerAdded) {
        feedbackButton.removeEventListener("click", toggleFeedbackPopup)
        delete feedbackButton.dataset.listenerAdded
    }

    document?.removeEventListener("click", handleClickOutside, true);
})
</script>