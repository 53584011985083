<template>
    <div>
        <TheHeader />
        <div v-if="!loadingState && isReady">
            <SidebarNavigation :menus="sidebarMenus" :collapsed="isCollapsed" :activeMenu="activeMenu"
                @menuSelected="handleMenuSelected" @collapseChanged="handleCollapsed" />
            <div :class="['sidebar-content-wrapper', { 'sidebar-collapsed': isCollapsed }]">
                <div class="sidebar-content">
                    <Card>
                        <template #content>
                            <ProMonDashboard v-if="activeMenu === 'activity'"></ProMonDashboard>
                            <CandidateList v-else :queue="activeMenu" :key="activeMenu"
                                @updateQueue="handleUpdateQueue" @qsAllQueue="handleQuickSearchAllQueue" />
                        </template>
                    </Card>
                </div>
            </div>
        </div>
    </div>

    <AppMonitor />
</template>

<script setup lang="ts">
import TheHeader from "@/components/TheHeader.vue"
import AppMonitor from "@/components/AppMonitor.vue"
import CandidateList from "@/components/Candidates/CandidateList.vue"
import SidebarNavigation from "@/components/Shared/SidebarNavigation.vue"
import ProMonDashboard from "@/components/Candidates/ProMonDashboard.vue"
import type { SidebarNavigationMenu } from "@/helpers/interface/candidates"
import { validateUserPermission, QueueMap, setPageTitle, promonQueueTabs, DEFAULT_HOMEPAGE, ucwords } from "@/helpers/common"
import { useRoute } from "vue-router"
import { ref, computed, watch, onMounted } from "vue"
import router from "@/router"
import { useAPI } from "@/helpers/services/api";
import { useAuth0 } from "@auth0/auth0-vue"
import Card from 'primevue/card';
import { useProfile } from "@/stores/profile"
import dayjs from "dayjs"
import { useTaxroll } from '@/stores/taxroll'

const route = useRoute()
const api = useAPI()
const { user } = useAuth0()
const storeProfile = useProfile()
const storeTaxroll = useTaxroll()
let routeQueue = route?.params?.queue
const loadingState = computed(() => storeProfile.loadingState)
const isReady = ref(false)
const activeMenu = ref(routeQueue as string)
const hasPermissionToReadCandidates = computed(() => validateUserPermission("read", "candidates"))
const hasPermissionToViewUnreleasedIR = computed(() => (storeProfile.canViewUnreleasedIRList))
const hasPermissionToViewReleasedIR = computed(() => (storeProfile.canViewReleasedIRList))
const hasPermissionToViewInvestigateNow = computed(() => (storeProfile.canViewInvestigateNowList))
const investigationServicesEnabled = computed(() => (storeProfile.investigationServicesEnabled))

const updateMenuBadgeCount = async (menuId: string, filters: {}[] = []) => {
    if (!hasPermissionToReadCandidates.value && user.value.sub) {
        filters.push({
            field: "assigned_to",
            type: "in",
            value: [user.value.sub]
        })
    }
    const data = await api.post("/taxroll/count", filters)
    const count = data?.data?.count || 0
    const menuItem = sidebarMenus.value.find(menu => menu.id === menuId)
    if (menuItem) menuItem.badge = count
}
const updateInvestigateNowCount = () => {
    const filters = [{ field: "queue", type: "=", value: "investigate_now" }]
    updateMenuBadgeCount("investigate_now", filters)
}
const updateInvestigateReportsCount = () => {
    if (investigationServicesEnabled.value) {
        const filters = [{ field: "queue", type: "=", value: "released_ir" }]
        updateMenuBadgeCount("released_ir", filters)
    }
}
const updateExpiredSnoozeCount = () => {
    const today = dayjs().format('YYYY-MM-DD')
    const filters = [
        { field: "queue", type: "=", value: "snooze" },
        { field: "snooze_until", type: "<=", value: today }
    ]
    updateMenuBadgeCount("snooze", filters)
}
const updateAllBadges = (queue: string, prevQueue: string | null = null, all: boolean = true) => {
    if ([queue, prevQueue].includes("investigate_now") || all) updateInvestigateNowCount()
    if ([queue, prevQueue].includes("snooze") || all) updateExpiredSnoozeCount()
    if ([queue, prevQueue].includes("released_ir") || all) updateInvestigateReportsCount()
}
const handleUpdateQueue = (bulkQueue: { prevQueue: string, queue: string }) => updateAllBadges(bulkQueue.queue.replace(" ", "_"), bulkQueue.prevQueue, false)
const sidebarMenus = ref<SidebarNavigationMenu[]>(promonQueueTabs)

const updateInvestigationMenuVisibility = (menuId: string, show: boolean) => {
    if (routeQueue === menuId && !show) {
        window.location.href = DEFAULT_HOMEPAGE
    }

    sidebarMenus.value.forEach((menu) => {
        if (menu.id === menuId) {
            menu.hidden = !show
        }
    })
}

watch(() => hasPermissionToViewInvestigateNow.value, (show: boolean) => updateInvestigationMenuVisibility('investigate_now', show))
watch(() => hasPermissionToViewReleasedIR.value, (show: boolean) => updateInvestigationMenuVisibility('released_ir', show))
watch(() => hasPermissionToViewUnreleasedIR.value, (show: boolean) => updateInvestigationMenuVisibility('unreleased_ir', show))

const isCollapsed = ref(false)
const navigateToQueue = (menuId = "all") => {
    activeMenu.value = menuId
    setPageTitleForQueue(menuId)
    router.push({ name: "candidateList", params: { queue: activeMenu.value } })

    if (menuId !== "all") {
        storeTaxroll.setQuickSearchQuery("")
    }
}
const handleQuickSearchAllQueue = () => navigateToQueue("all")
const handleMenuSelected = (menuId: string) => navigateToQueue(menuId)

const handleCollapsed = (collapsed: boolean) => isCollapsed.value = collapsed

watch(() => route?.params?.queue, (queue) => {
    routeQueue = queue
    updateAllBadges(routeQueue as string)
})

const setPageTitleForQueue = (queue?: string) => {
    const currentQueue = queue || window.location.pathname.split("/").pop() || ""

    const queueMapping = Object.fromEntries(
        Object.values(QueueMap).map(({ id, text }) => [id, text])
    )

    const queueName = ucwords(queueMapping[currentQueue] || currentQueue)
    setPageTitle(`${queueName} - Proactive Monitoring`)
}

onMounted(async () => {
    setPageTitleForQueue()

    const requests = [
        storeProfile.fetchUserPermissions(),
        storeProfile.fetchCustomerAccess()
    ]

    await Promise.all(requests).then(() => {
        isReady.value = true
    })

    updateInvestigationMenuVisibility('unreleased_ir', hasPermissionToViewUnreleasedIR.value)
    updateInvestigationMenuVisibility('released_ir', hasPermissionToViewReleasedIR.value)
    updateInvestigationMenuVisibility('investigate_now', hasPermissionToViewInvestigateNow.value)
    
    updateAllBadges(routeQueue as string)
})
</script>

<style>
@import "@/assets/candidates-list.css";
</style>
