<template>
  <div ref="IngestionDataView" id="IngestionDataView">
    <div class="row action-toolbar-container" id="page-header-action-section">
      <div class="col-6" v-if="!loading">
        <Button type="button" class="me-2" @click="SendToWindow($router.resolve({ name: 'ingestion', params: { data_request_id: 'new' }}).href)" severity="info">
          <span class="fa fa-plus-circle" aria-hidden="true"></span> New
        </Button>
        <Button type="button" :disabled="(selectedItems.length <= 0)" class="me-2" @click="hideIngestion" severity="danger">
          <span class="fa fa-trash" aria-hidden="true"></span> Hide
        </Button>
        <Button type="button" class="me-2" @click="SendToWindow($router.resolve({ name: 'generate_observations', params: { job_id: 'new' }}).href)" severity="warning">
          <span class="fa fa-archive" aria-hidden="true"></span> Generate Extracts
        </Button>
        <Button type="button" class="me-2" @click="SendToWindow($router.resolve({ name: 'generate_extracts', params: { job_id: 'new' }}).href)" severity="success">
          <span class="fa fa-layer-group" aria-hidden="true"></span> Generate Observations
        </Button>
      </div>
      <div class="col-4" v-else>
        <Skeleton width="400px" height="34px" class="ms-2 mb-2"></Skeleton>
      </div>
      <div class="col-2" v-if="!loading">
        <input type="text" class="form-control" id="customerInput" placeholder="Select Customer"
          list="customers" v-model="customerSelectedLabel">
        <datalist id="customers">
          <option v-for="customer in state.customers" :key="customer.customer_id"
            :value="customer.customer_id" :label="`${customer.name} (${customer.customer_id})`" />
        </datalist>
      </div>
      <div class="col-2" v-if="!loading">
        <Button v-if="customer_id" type="button" class="me-2" @click="customer_id = '';" severity="secondary">
          <span class="fa fa-solid fa-circle-xmark" aria-hidden="true"></span>
        </Button>
      </div>
      <div class="col-2" v-else>
        <Skeleton width="225px" height="34px" class="ms-2 mb-2"></Skeleton>
      </div>
      <div class="ms-auto" style="width: fit-content;" v-if="!loading">
        <div>
          <Checkbox v-model="showHidden" :input-id="'showHidden'" binary/>
          <label for="showHidden">Show Hidden</label>
        </div>
      </div>
      <div class="ms-auto" style="width: fit-content;" v-else>
        <Skeleton width="150px" height="16px" class="ms-2 mb-2"></Skeleton>
      </div>
    </div>

    <div v-if="loading">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td colspan="6" style="text-align: center">
              <div class="spinner-border" style="width: 100px; height: 100px;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <BasicStrapCards :loading="loading" :parent-data-view="IngestionDataView" :total-records="totalRecords" :rows="rows" :first-index="first" :data="data" @onpage="(event) => onPage(event)" :key="reloader">
      <template #list="slotProps">
        <div v-for="(item, index) in (slotProps.data as Ingest[])" :key="index" class="row dataview-list-row-card" :id="`row_${index}`">
          <div class="col-1 align-content-center" id="selectionColumn">
            <div class="p-3">
              <Checkbox v-model="selectedItems" :inputId="item.id" name="category" :value="item.id" />
            </div>
          </div>
          <div class="col align-content-center clipboard-copy-wrapper">
            <div class="p-3">
              <div class="mb-2">
                <a :href="'/onboarding/ingestions/' + item.id" target="_blank">
                  {{ getCustomer(item.customer_id) }}
                </a>
              </div>
              <div class="mb-2">
                {{ getSchema(item.customer_id) }}
              </div>
              <div class="mb-2">
                <a :href="'/onboarding/configs/' + item.config_id" target="_blank">
                  {{ getConfig(item.config_id) }}
                </a>
              </div>
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2">
              {{ item.status.toUpperCase() }}
            </div>
            <div class="mb-2">
              Started At: {{ getDateTime(item.started_at || '') || 'Ingestion Not Started' }}
            </div>
            <div class="mb-2">
              Completed At: {{ getDateTime(item.finished_at || '') || 'Ingestion Not Finished' }}
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2">
              Extracts: {{ getDateTime(getJob(item.customer_id, 'generate_extracts')) || 'Job Never Run' }}
            </div>
          </div>
          <div class="col align-content-center">
            <div class="mb-2">
              Flags: {{ getDateTime(getJob(item.customer_id, 'generate_observations')) || 'Job Never Run' }}
            </div>
          </div>
        </div>
      </template>
    </BasicStrapCards>
  </div>
</template>

<script setup lang="ts">
import { useAPI } from "@/helpers/services/api"
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import {computed, onMounted, reactive, ref, watch} from "vue";
import type {Ingest, LightConfig, Job} from "@/helpers/interface/onboarding";
import {getApiErrorMessage, hidePFGetHelpButton, scrollToView} from '@/helpers/common';
import Checkbox from "primevue/checkbox";
import BasicStrapCards from "@/components/Shared/BasicStrapCards.vue";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton";
import type {DataTableEvent} from "@/helpers/interface/general";
import {toast} from "@/helpers/toast";
import type {Customer} from "@/helpers/interface/trueload";

const state = reactive({
  customers: [] as Array<Customer>,
  configs: [] as Array<LightConfig>,
  jobs: [] as Array<Job>,
})

const IngestionDataView = ref<HTMLElement | null>(null);
const loading = ref(false);
const first = ref(0);
const rows = ref(25);
const data = ref<Ingest[]>([]);
const totalRecords = ref(0);
const currentPage = ref(1);
const dataParams = ref<DataTableEvent | null>(null);
const reloader = ref(0)

const customer_id = ref('');

const selectedItems = ref<string[]>([]);

const showHidden = ref<boolean>(false);

const emits = defineEmits(["onpage"])

const api = useAPI()

watch([customer_id, showHidden], () => {
  first.value = 0;
  onPage({page: 0, first: 0, rows: rows.value});
  reloader.value += 1;
})

const onPage = async (event: DataTableEvent) => {
    event.page += 1
    dataParams.value = event
    currentPage.value = event.page
    await loadData(event)
    scrollToView("row_0")
}

const loadData = async (event: any = null) => {
    loading.value = true
    dataParams.value = { ...dataParams.value, page: event?.page || 1, rows: event?.rows || 25 }
    let payload = configParams(dataParams.value)

    payload.page = currentPage.value

    const requests = []

    const ig_filter = {
      "limit": parseInt(payload.page_size),
      "offset": (parseInt(payload.page_size) * (parseInt(payload.page) - 1)),
      "show_hidden": showHidden.value,
      "customer_id": customer_id.value,
    }
    const listRequest = api.post(`/onboarding/ingestions/list`, ig_filter)
        ?.then(response => {
            data.value = response.data || []
        })
        ?.catch(error => {
            data.value = []
            toast.error(getApiErrorMessage(error))
        })
    requests.push(listRequest)

    const countRequest = api.post(`/onboarding/ingestions/count`, ig_filter)
        ?.then(response => {
            totalRecords.value = response.data || 0
        })
        ?.catch(error => {
            totalRecords.value = 0
            toast.error(getApiErrorMessage(error))
        })
    requests.push(countRequest)

    await Promise.all(requests)
    loading.value = false
}

const SendToWindow = (url: string) => {
  window.open(url, '_blank')
}

const customerSelectedLabel = computed({
  get() {
    return customer_id.value ? `${state.customers.find(c => c.customer_id == customer_id.value)?.name} (${customer_id.value})` : ''
  },
  set(value) {
    if (state.customers.find(c => c.customer_id == value)) {
      customer_id.value = value
    }
  }
})

const configParams = (event: any) => {
    rows.value = event?.rows
    return {
        "page": event?.page,
        "page_size": event?.rows,
        "include_total": false
    }
}

const hideIngestion = async () => {
  loading.value = true
  for (const id of selectedItems.value) {
    try {
      await api.patch(`onboarding/ingestions/hide/${id}`)
    }
    catch (error: any) {
      toast.error(getApiErrorMessage(error))
    }
  }
  selectedItems.value = []
  await loadData({page: currentPage, rows: rows})
  loading.value = false
}

const getDateTime = (date_str: string) => {
  if (date_str) {
    const date = new Date(date_str)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  }
  else {
    return ''
  }
}

const getConfig = (config_id: string) => {
  return state.configs.find(item => item.id === config_id)?.name || "Config Not Found"
}

const getSchema = (customer_id: string) => {
  return state.customers.find(item => Number(item.customer_id) === Number(customer_id))?.schema_name || "Schema Not Found"
}

const getCustomer = (customer_id: string) => {
  return state.customers.find(item => Number(item.customer_id) === Number(customer_id))?.name || "Customer Not Found"
}

const getJob = (customer_id: string, job_type: string) => {
  return state.jobs.find(item => Number(item.customer_id) === Number(customer_id) && item.job_type === job_type)?.created_at || ""
}

const get_customers = async () => {
  loading.value = true
  const cust_response = await api.get(`customers/`)
  state.customers = cust_response.data
  loading.value = false
}

const get_configs = async () => {
  loading.value = true
  const config_response = await api.get(`onboarding/configs/`);
  state.configs = config_response.data;
  loading.value = false
}

const get_jobs = async () => {
  loading.value = true
  const job_response = await api.get(`jobs/all/`);
  state.jobs = job_response.data;
  loading.value = false
}

onMounted(async () => {
  await get_customers()
  await get_configs()
  await get_jobs()
  await loadData()
  hidePFGetHelpButton()
});

</script>

<style>

</style>