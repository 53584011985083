<template>
    <div></div>
</template>
<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import router from "@/router"

const { loginWithRedirect, isAuthenticated } = useAuth0();
// callback will handle the default "homepage"
const targetRouter = JSON.parse(
    import.meta.env.VITE_LOGIN_TARGET_ROUTER || '{"name": "callback"}'
)
if (!isAuthenticated.value) {
    loginWithRedirect({
        appState: {
            target: router.resolve(targetRouter).href,
        }
    });
} else {
    router.push(targetRouter);
}
</script>
