<template>
  <div class="" v-if="!removal">
    <div class="modal-dialog" v-if="!state.loading">
      <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title">
                <h5><b>Assign Config:</b></h5>
            </div>
        </div>
        <hr>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="customerInput" class="form-label">Customer</label>
            <input type="text" class="form-control" id="customerInput" placeholder="Select Customer"
              list="customers" v-model="customerSelectedLabel">
            <datalist id="customers">
              <option v-for="customer in state.customers" :key="customer.customer_id"
                :label="`${customer.name} (${customer.customer_id})`" :value="customer.customer_id"/>
            </datalist>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="configInput" class="form-label">Config</label>
            <input type="text" class="form-control" id="configInput" placeholder="Select Config"
              list="configList" v-model="configSelectedLabel">
            <datalist id="configList">
              <option v-for="config in state.configs" :key="config.id"
                :label="`${config.name} (${config.id})`" :value="config.id" />
            </datalist>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="configType" class="form-label">Config Type</label>
            <select id="configType" class="form-control" placeholder="Config Type" v-model="state.config_type">
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <button type="button" class="btn btn-outline-primary btn-sm m-1" @click="assignConfig" :disabled="!state.config_type || !state.customer_id || !state.config_id">
            Assign Config
          </button>
        </div>
        <div class="modal-footer">
          <button @click="closePopup" style="border: 1px solid #ccc" class="btn btn-default">Close</button>
        </div>
      </div>
    </div>
    <loading-icon v-else />
  </div>
  <div class="" v-else>
    <div class="modal-dialog" v-if="!state.loading">
      <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title">
                <h5><b>Unassign Config:</b></h5>
            </div>
        </div>
        <hr>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="customerInput" class="form-label">Customer</label>
            <input type="text" class="form-control" id="customerInput" placeholder="Select Customer"
              list="customers" v-model="customerSelectedLabel">
            <datalist id="customers">
              <option v-for="customer in state.customers" :key="customer.customer_id"
                :label="`${customer.name} (${customer.customer_id})`" :value="customer.customer_id"/>
            </datalist>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <div class="mb-3">
            <label for="configType" class="form-label">Config Type</label>
            <select id="configType" class="form-control" placeholder="Config Type" v-model="state.config_type">
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-body" v-if="!state.loading">
          <button type="button" class="btn btn-outline-primary btn-sm m-1" @click="unassignConfig" :disabled="!state.config_type || !state.customer_id">
            Unassign Config
          </button>
        </div>
        <div class="modal-footer">
          <button @click="closePopup" style="border: 1px solid #ccc" class="btn btn-default">Close</button>
        </div>
      </div>
    </div>
    <loading-icon v-else />
  </div>
</template>

<script setup lang="ts">

import {computed, onMounted, reactive, ref} from 'vue';
import type { LightConfig } from "@/helpers/interface/onboarding";
import type { LightCustomer } from "@/helpers/interface/trueload";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import { useAPI } from "@/helpers/services/api";
import { toast } from "@/helpers/toast";

const api = useAPI()

const emits = defineEmits(['closePopup'])
const closePopup = () => emits("closePopup", false)

const props = defineProps({
    customer_id: {
      type: String,
      default: '',
    },
    config_id: {
      type: String,
      default: '',
    },
    config_type: {
      type: String,
      default: '',
    },
    removal: {
      type: Boolean,
      default: false,
    }
})

const state = reactive({
  customer_id: '',
  config_id: '',
  config_type: '',
  removal: false,
  configs: [] as Array<LightConfig>,
  customers: [] as Array<LightCustomer>,
  loading: false,
})

const options = ref([
      { value: 'taxroll_config', text: 'Taxroll' },
      { value: 'ocr_config', text: 'OCR Applications' },
      { value: 'digital_config', text: 'Digital Applications' },
    ]);

const assignConfig = async () => {
  try {
    await api.patch(`onboarding/ingestions/configs/assign/${state.customer_id}/${state.config_type}/${state.config_id}`)
  } catch (e: any) {
    toast.error(e?.message || "Error Assigning Config")
    throw e
  } finally {
    toast.success("Config Assigned")
  }
}

const unassignConfig = async () => {
  try {
    await api.patch(`onboarding/ingestions/configs/unassign/${state.customer_id}/${state.config_type}`)
  } catch (e: any) {
    toast.error(e?.message || "Error Unassigning Config")
    throw e
  } finally {
    toast.success("Config Unassigned")
  }
}

const getCustomers = async () => {
  const response = await api.get(`customers/light`);
  state.customers = response.data;
}

const getConfigs = async () => {
  const response = await api.get(`onboarding/configs/`);
  state.configs = response.data;
}

const customerSelectedLabel = computed({
  get() {
    return state.customer_id ? `${state.customers.find(c => c.customer_id == state.customer_id)?.name} (${state.customer_id})` : ''
  },
  set(value) {
    if (state.customers.find(c => c.customer_id == value)) {
      state.customer_id = value
    }
  }
})

const configSelectedLabel = computed({
  get() {
    return state.config_id ? `${state.configs.find(c => c.id == state.config_id)?.name} (${state.config_id})` : ''
  },
  set(value) {
    if (state.configs.find(c => c.id == value)) {
      state.config_id = value
    }
  }
})

onMounted(async () => {
  state.loading = true
  state.customer_id = props.customer_id
  state.config_id = props.config_id
  state.config_type = props.config_type
  state.removal = props.removal
  await Promise.all([
    getCustomers(),
    getConfigs()
  ])
  state.loading = false
})

</script>
