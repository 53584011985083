<template>
    <div class="actions-form">
        <div class="actions-description">
            Updating a Candidate Queue allows you to track details and status changes that align with
            your office's workflow.
        </div>
        <form id="candidate-update-queue-form" @submit.prevent="toggleUpdateQueueAction('confirm')">
            <div class="row pt-2">
                <div class="col-4">
                    Queue <span class="required">*</span>
                </div>
                <div class="col-6">
                    <select class="cbFormTextField" v-model="formUpdate.queue" required id="queueOptions">
                        <option value="" selected>-- Select --</option>
                        <option v-for="q in availableQueueOptions" :value="q.value" :key="q.value">
                            {{ q.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row pt-2" v-if="queuesWithReason.includes(formUpdate.queue) ? !fetchReasonListLoading : false">
                <div class="col-4">
                    Reason <span class="required" v-if="queuesWithRequiredReason.includes(formUpdate.queue)">*</span>
                </div>
                <div class="col-6">
                    <select class="cbFormTextField" v-model="formUpdate.reason"
                        :required="queuesWithRequiredReason.includes(formUpdate.queue)" id="reasonsDropdown">
                        <option value="" selected>-- Select --</option>
                        <option v-for="reason in reasonsDropdown" :value="reason" :key="reason">
                            {{ ucfirst(reason) }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="pt-2" v-if="formUpdate.queue === 'archive'">
                <div class="row pt-2">
                    <div class="col-4">
                        Sale Date
                    </div>
                    <div class="col-6">
                        <input type="date" id="saleDate" v-model="saleDateObj" class="cbFormTextField">
                    </div>
                </div>
            </div>
            <div class="pt-2" v-if="formUpdate.queue === 'snooze'">
                <div class="actions-description">
                    Move the Candidate to SNOOZE if you want to defer the review. The Candidate will return to the
                    top
                    of
                    your inbox on the "Snooze Until" date.
                </div>
                <div class="row pt-2">
                    <div class="col-4">
                        Snooze Until <span class="required">*</span>
                    </div>
                    <div class="col-6">
                        <input type="date" id="snoozeUntil" v-model="formUpdate.snooze_until" class="cbFormTextField"
                            :min="tomorrowDate" required>
                    </div>
                </div>
            </div>
            <div class="pt-2" v-if="formUpdate.queue === 'questionnaire'">
                <div class="actions-description">
                    Move the Candidate to QUESTIONNAIRE if you need to gather more information from the taxpayer. If
                    you'd
                    like to send the taxpayer mail, select a Mailing Template.
                </div>
                <div class="row pt-2">
                    <div class="col-4">
                        Mailing Template
                    </div>
                    <div class="col-6">
                        <select class="cbFormTextField" v-model="mailingTemplateWrapper"
                            id="mailingTemplateDropdownQuestionnaire">
                            <option value="" selected>-- Select --</option>
                            <option v-for="template in mailingTemplateDropdown" :value="template.id" :key="template.id">
                                {{ template.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="pt-2" v-if="formUpdate.queue === 'unqualified'">
                <div class="actions-description">
                    Move the Candidate to UNQUALIFIED if the exemption needs to be removed, denied for a future
                    year,
                    and/or
                    billed for previous years.
                </div>
                <div class="row pt-2">
                    <div class="col-4">
                        Unqualified Start Yr <span class="required">*</span>
                    </div>
                    <div class="col-6">
                        <UnqualifiedStartYear id="unqualifiedStartYearFieldUnqualified"
                            @fieldUpdate="(value: any) => updateUnqualifiedStartYear(value)"
                            :unqualifiedStartYear="formUpdate.unqualified_start_year" />
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-4">
                        Mailing Template
                    </div>
                    <div class="col-6">
                        <select class="cbFormTextField" v-model="mailingTemplateWrapper"
                            id="mailingTemplateDropdownUnqualified">
                            <option value="" selected>-- Select --</option>
                            <option v-for="template in mailingTemplateDropdown" :value="template.id" :key="template.id">
                                {{ template.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-4">
                        Recovered Amount
                    </div>
                    <div class="col-6">
                        <input type="text" inputmode="decimal" pattern="[0-9]+(\.[0-9][0-9])?" class="cbFormTextField"
                            v-model="formUpdate.recovered_amount" maxlength="15" placeholder="for reporting"
                            :title="MONETARY_INPUT_TOOLTIP" id="recoveredAmountUnqualified">
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-4">
                        Back Taxes / Lien
                    </div>
                    <div class="col-6">
                        <input type="checkbox" id="lienOrBackTaxesUnqualified" v-model="formUpdate.lien_or_back_taxes" />
                    </div>
                </div>
                <div class="row pt-2" v-if="formUpdate.lien_or_back_taxes">
                    <div class="col-4">
                        Back Taxes to Bill
                    </div>
                    <div class="col-6">
                        <input type="text" id="lienAmountUnqualified" inputmode="decimal"
                            pattern="[0-9]+(\.[0-9][0-9])?" class="cbFormTextField" v-model="formUpdate.lien_amount"
                            maxlength="15" :title="MONETARY_INPUT_TOOLTIP">
                    </div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-4">
                    Note
                </div>
                <div class="col-6">
                    <textarea v-model="formUpdate.note" id="addNoteField" class="textwrapper"
                        :maxlength="MAX_NOTE_LENGTH"></textarea>
                </div>
            </div>

            <div class="row pt-2">
                <div class="offset-4 col-6 d-flex">
                    <loading-icon v-if="formLoading || fetchReasonListLoading || fetchMailingTemplateLoading" />

                    <button v-if="!confirmationNeeded" class="searchButton" type="submit"
                        :disabled="formLoading || fetchReasonListLoading || fetchMailingTemplateLoading"
                        id="update-btn">Update</button>
                    <button v-else class="searchButton" type="button" @click="archiveSubmitHandler"
                        :disabled="formLoading || fetchReasonListLoading || fetchMailingTemplateLoading"
                        id="update-archive-btn">Update</button>
                </div>
            </div>
        </form>
    </div>

    <ModalDialog v-if="showArchiveConfirmDialog" title="Archive Candidate" :close="() => { }">
        <p>
            Are you sure you want to archive this candidate?
        </p>
        <template #footer>
            <loading-icon v-if="formLoading" />
            <button @click="submitUpdateForm()" :disabled="formLoading"
                class="searchButton removeButton">Archive</button>
            <button @click="showArchiveConfirmDialog = false" class="searchButton clearButton">Cancel</button>
        </template>
    </ModalDialog>

    <ModalDialog v-if="showArchiveWarningModal" title="Queue Update Confirmation"
        :close="() => showArchiveWarningModal = false">
        <Message severity="warn" :closable="false" class="my-2">
            This candidate is archived.
            <br>
            <br>
            Are you sure you want to proceed with this change?
        </Message>

        <template #footer>
            <Button @click="toggleUpdateQueueAction('submit')" severity="warning"
                :disabled="formLoading || fetchReasonListLoading || fetchMailingTemplateLoading">
                <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!formLoading"></span>
                <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-else></span>
                Confirm
            </Button>
            <Button severity="secondary" @click="toggleUpdateQueueAction('close')">Close</Button>
        </template>
    </ModalDialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { queueOptions } from "@/helpers/dropdownOptions"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import type { CandidateFormUpdate } from "@/helpers/interface/candidates"
import { useAPI } from "@/helpers/services/api";
import { 
    ucfirst, 
    getApiErrorMessage, 
    MAX_NOTE_LENGTH, 
    MONETARY_INPUT_TOOLTIP, 
    validateUserPermission, 
    InternalSettings, 
    formatDate,
    ucwords, 
    QueueMap
} from "@/helpers/common";
import { useRoute } from "vue-router";
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import UnqualifiedStartYear from "@/components/Shared/UnqualifiedStartYear.vue"
import { toast } from "@/helpers/toast";
import dayjs from "dayjs"
import type { AxiosError } from "axios";
import { useProfile } from "@/stores/profile";
import { useAdminDetails } from "@/stores/adminDetails";
import Button from 'primevue/button'
import Message from 'primevue/message'

const route = useRoute();
const api = useAPI()
const storeProfile = useProfile()
const storeAdminDetails = useAdminDetails()
const storeCandidateDetails = useCandidateDetailsStore();

const fetchReasonListLoading = ref(false)
const fetchMailingTemplateLoading = ref(false)
const formLoading = ref(false)
const defaultRA = ref<number | null>(null)
const addNote = ref(false)
const currentQueue = computed({
    get() {
        return storeCandidateDetails.situsInfo.queue
    },
    set(value) {
        storeCandidateDetails.situsInfo.queue = value
    }
})
let snoozeDays = 0
const queuesWithReason: string[] = ["monitor", "unqualified", "snooze"]
const queuesWithRequiredReason: string[] = queuesWithReason.filter(reason => reason != 'snooze')
const showArchiveConfirmDialog = ref(false)

const reasonsDropdown = ref<string[]>([])
const mailingTemplateDropdown = computed(() => storeCandidateDetails.getMailTemplateList)
const tomorrowDate: string = dayjs().add(1, "day").toISOString().slice(0, 10)
const availableQueueOptions = ref(queueOptions)

const investigationServicesEnabled = computed(() => (storeProfile.investigationServicesEnabled))
watch(() => investigationServicesEnabled.value, (enabled: boolean) => {
    const optionIndex = availableQueueOptions.value.findIndex(option => option.value === "investigate_now");
    if (enabled && optionIndex === -1) {
        availableQueueOptions.value.push({ value: "investigate_now", text: ucwords(QueueMap.InvestigateNow.text) });
    }
    else if (!enabled && optionIndex !== -1) {
        availableQueueOptions.value.splice(optionIndex, 1);
    }
})

const toggleOption = (enabled: boolean, value: string, text: string) => {
    const optionIndex = availableQueueOptions.value.findIndex(option => option.value === value);
    if (enabled && optionIndex === -1) {
        availableQueueOptions.value.push({ value, text });
    }
    else if (!enabled && optionIndex !== -1) {
        availableQueueOptions.value.splice(optionIndex, 1);
    }
};

const hasPermissionForUnreleasedInvestigationQueue = computed(() => validateUserPermission("read", "unreleased_investigation_reports"))
watch(() => hasPermissionForUnreleasedInvestigationQueue.value, (show) => displayUnderInvestigationOption(show))
const displayUnderInvestigationOption = (enabled: boolean) => {
    toggleOption(enabled, "unreleased_ir", "Under Investigation")
    toggleOption(enabled, "released_ir", "Investigation Reports")
}

const formUpdateInitialState = {
    queue: "",
    reason: "",
    final_outcome: "",
    snooze_until: "",
    mailing_template: null,
    unqualified_start_year: "",
    lien_or_back_taxes: false,
    recovered_amount: null,
    lien_amount: null,
}
const saleDateObj = ref<Date | null>(null)
const formUpdate = ref<CandidateFormUpdate>({ ...formUpdateInitialState })
const info = computed(() => (storeCandidateDetails.situsInfo))
const tru_id = route?.params?.tru_id as string

const mailingTemplateWrapper = computed({
    get() {
        return formUpdate.value.mailing_template?.toString() || ""
    },
    set(value) {
        formUpdate.value.mailing_template = value ? parseInt(value, 10) : null
    }
})

// On Load
watch(() => currentQueue.value, (updatedQueue) => {
    formUpdate.value.queue = updatedQueue
    fetchReasonList(updatedQueue)
});

watch(() => addNote.value, () => {
    formUpdate.value.note = ""
})

watch(() => formUpdate.value.reason, () => {
    if (formUpdate.value.reason == 'Other') addNote.value = true
    else addNote.value = false
})

// Request Reason List
watch(() => formUpdate.value.queue, async (updatedQueue) => {
    fetchReasonList(updatedQueue)
    await storeCandidateDetails.fetchMailTemplateList(loadFailureHandler)
});

const confirmationNeeded = computed(() => {
    return formUpdate.value.queue == "archive"
})

let fetchReasonList = async (queueSelected: string) => {
    if (!queuesWithReason.includes(queueSelected)) return

    formUpdate.value.reason = ""
    fetchReasonListLoading.value = true;

    try {
        const { data } = await api.get(`/taxroll/reasons?category=${encodeURIComponent(queueSelected)}`);
        if (data === undefined || !data.data) reasonsDropdown.value = [];
        else reasonsDropdown.value = data.data.reasons;
    }
    catch (error) {
        reasonsDropdown.value = [];
    }
    finally {
        fetchReasonListLoading.value = false;
    }
}

const loadFailureHandler = (error: AxiosError) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}

const archiveSubmitHandler = () => {
    const form = document.getElementById("candidate-update-queue-form") as HTMLFormElement
    if (form.checkValidity())
        showArchiveConfirmDialog.value = true
    else
        form.reportValidity()
}

const updateUnqualifiedStartYear = (value: string) => formUpdate.value.unqualified_start_year = value

const showArchiveWarningModal = ref(false)
const toggleUpdateQueueAction = (action: "confirm" | "submit" | "close") => {
    if (action === "confirm") {
        if (info.value.queue === "archive") {
            showArchiveWarningModal.value = true
        }
        else submitUpdateForm()
        return
    }

    if (action === "submit") {
        submitUpdateForm()
        return
    }

    if (action === "close") {
        showArchiveWarningModal.value = false
        return
    }
}

// Submit Form
let submitUpdateForm = async () => {

    if ((formUpdate.value.snooze_until)) formUpdate.value.snooze_until = dayjs(formUpdate.value.snooze_until).toISOString().substring(0, 10)

    const {
        queue,
        note,
        reason,
        snooze_until,
        mailing_template,
        unqualified_start_year,
        lien_or_back_taxes,
        recovered_amount,
        lien_amount,
    } = formUpdate.value;

    formLoading.value = true

    // Config parameters
    let params = { queue, note } as CandidateFormUpdate
    if (formUpdate.value.queue === 'archive') {
        const final_outcome = "Property Sold"
        const sale_date = saleDateObj.value || null
        if (sale_date) {
            const soldPrefix = `Sold ${formatDate(sale_date.toString())}`;
            params.note = (!params.note) ? soldPrefix : `${soldPrefix} 
            ${params.note}`;
        }

        params = { ...params, final_outcome }
    }
    else if (formUpdate.value.queue === 'monitor') {
        params = { ...params, reason }
    }
    else if (formUpdate.value.queue === 'snooze') {
        // convert snooze_until partial ISO like "2023-10-04" to full ISO string including time
        const snoozeUntilValue = dayjs(snooze_until).toISOString()
        params = { ...params, reason, snooze_until: snoozeUntilValue }
    }
    else if (formUpdate.value.queue === 'questionnaire') {
        params = { ...params, mailing_template }
    }
    else if (formUpdate.value.queue === 'unqualified') {
        let data: any = {
            ...params,
            unqualified_start_year,
            lien_or_back_taxes,
            recovered_amount,
            reason,
            mailing_template
        }
        if (data.lien_or_back_taxes && lien_amount)
            data.lien_amount = lien_amount
        params = data
    }

    const payload = { ...params, tru_ids: [tru_id] }

    await api.patch("/taxroll/queue", payload)?.then(async () => {
        showArchiveConfirmDialog.value = false
        currentQueue.value = queue
        await storeCandidateDetails.fetchHistory(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchMailList(tru_id, loadFailureHandler)
        await storeCandidateDetails.fetchSitusInfo(tru_id, loadFailureHandler)
        toast.success("Thank you for your Candidate update.")

        // Reset
        formUpdate.value = { ...formUpdateInitialState }
        saleDateObj.value = null
        formUpdate.value.queue = currentQueue.value
        if (["unqualified", "archive"].includes(queue)) {
            formUpdate.value.recovered_amount = recovered_amount
            defaultRA.value = recovered_amount || null
        }
        addNote.value = false
    })?.catch((error: AxiosError) => {
        toast.error(getApiErrorMessage(error))
    })?.finally(() => {
        showArchiveConfirmDialog.value = false
        formLoading.value = false;
        showArchiveWarningModal.value = false
    })
}
const getDefaultSnoozeDays = async () => {
    const d = await storeAdminDetails.getDefaultSnoozeDays(InternalSettings.promon_default_snooze_days)
    if (d) {
        snoozeDays = d
        formUpdate.value.snooze_until = dayjs().add(snoozeDays, "day").format("YYYY-MM-DD")
    }
}

onMounted(() => {
    getDefaultSnoozeDays()
})

</script>

<style>
@import "@/assets/candidate-details.css";
</style>
